import React, { useEffect, useState } from "react";
import {
  getRequestCallByDispatchers,
  searchDispatcherRequestCall,
} from "../../API";
import { useDispatch, useSelector } from "react-redux";
import {
  addRequest,
  setRequests,
  updateRequest,
} from "../../redux/reducers/requestReducer";
import DispatcherRequestCallTable from "../../components/DispatcherRequestCallTable";
import { checkDispatcherRequests } from "../../components/socket/StatusSocket";
import { resevedTimer } from "../../components/socket/timerSocket";
import { logoutReducer } from "../../redux/reducers/authReducer";
import { ALL_JOBS, IN_PROGRESS } from "../../GlobalFunctions";

const RequestedCalls = () => {
  const [loading, setLoading] = useState(false);
  const { userId } = useSelector((state) => state.auth);
  const { requests } = useSelector((state) => state.requests);
  const [searchKey, setSearchKey] = useState("Select Filter");
  const [noDataFoundShow, setNoDataFoundShow] = useState(false);
  const { sideBar } = useSelector((state) => state.setSideBar);
  const [totals, setTotals] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const dispatch = useDispatch();

  const handleGetRequestData = () => {
    setLoading(true);
    getRequestCallByDispatchers(currentPage, 10).then((res) => {
      if (res.success) {
        dispatch(setRequests(res.requestTech));
        setTotalPages(res.totalPages);
        setTotals({
          requestTechTotal: res.requestTechTotal,
          requestTechPending: res.requestTechToTechnician,
        });
        setLoading(false);
      } else {
        setNoDataFoundShow(true);
        setLoading(false);
        if (res === "The token is invalid or expired") {
          dispatch(logoutReducer());
        }
      }
    });
  };

  const handleSearch = (search) => {
    if (searchKey === "name" || searchKey === "workOrderNumber") {
      searchDispatcherRequestCall(searchKey, search)
        .then((res) => {
          if (res.success) {
            dispatch(setRequests(res.requestCalls));
          } else {
            setNoDataFoundShow(true);
            if (res === "The token is invalid or expired") {
              dispatch(logoutReducer());
            }
          }
        })
        .catch((error) => {
          console.error("Error searching requested calls:", error);
          setNoDataFoundShow(true);
        });
    } else {
      // Handle other search keys or cases here
    }
  };

  const handleFilter = (filter) => {
    if (filter === ALL_JOBS) {
      handleGetRequestData();
    } else if (filter === IN_PROGRESS) {
      setLoading(true);
      getRequestCallByDispatchers(currentPage, 10).then((res) => {
        if (res.success) {
          const inProgressRequests = res.requestTech.filter(
            (request) => request.status === "Technician Checked In"
          );
          dispatch(setRequests(inProgressRequests));
          setTotalPages(res.totalPages);
          setLoading(false);
        } else {
          if (res === "The token is invalid or expired") {
            dispatch(logoutReducer());
          }
        }
      });
    }
  };

  useEffect(() => {
    handleGetRequestData();
    checkDispatcherRequests(dispatch, addRequest, userId, updateRequest);
    resevedTimer(dispatch, updateRequest);
  }, [currentPage]);

  const tableHeader = [
    "#",
    "Name",
    "Location",
    "Work Order Number",
    "Time To Arrive",
    "Status",
    "Action",
  ];

  return (
    <>
      <div className={`p-4 ${sideBar && "sm:ml-64"}`}>
        <div className="p-4 rounded-lg mt-14">
          <span className="text-2xl font-bold text-gray-900 dark:text-white mb-4">
            Dispatcher Request Calls
          </span>
          <DispatcherRequestCallTable
            tableHeader={tableHeader}
            tableData={requests}
            loading={loading}
            setSearchKey={setSearchKey}
            searchKey={searchKey}
            handleSearch={handleSearch}
            noDataFoundShow={noDataFoundShow}
            totals={totals}
            handleFilter={handleFilter}
            totalPages={totalPages}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </div>
      </div>
    </>
  );
};

export default RequestedCalls;
