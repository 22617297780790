import { createSlice } from "@reduxjs/toolkit";
import { setLocaleString } from "../../../GlobalFunctions";

export const costs = createSlice({
  name: "costs",
  initialState: {
    costs: [],
    total: 0,
    NTEPrice: 0,
  },
  reducers: {
    setCosts: (state, action) => {
      state.costs = action.payload;
    },

    updateCosts: (state, action) => {
      state.costs = state.costs.map((cost) => {
        if (cost._id === action.payload._id) {
          return action.payload;
        } else {
          return cost;
        }
      });
    },

    addCost: (state, action) => {
      state.costs = [...state.costs, action.payload];
    },

    setTotal: (state, action) => {
      state.total = +action.payload;
    },

    addToTotal: (state, action) => {
      state.total = +state.total + +action.payload;
    },

    addNTEPrice: (state, action) => {
      state.NTEPrice = +action.payload;
    },

    deleteCost: (state, action) => {
      state.costs = state.costs.filter((cost) => cost._id !== action.payload);
    },
  },
});

export const {
  setCosts,
  updateCosts,
  addCost,
  deleteCost,
  setTotal,
  addToTotal,
  addNTEPrice,
} = costs.actions;

export default costs.reducer;
