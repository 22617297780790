import React from "react";
import { Line } from "react-chartjs-2";
import { setLocaleString } from "../../GlobalFunctions";

function LineChart({ chartData, salary }) {
  const options = {
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          color: "white",
        },
      },
      x: {
        ticks: {
          color: "white",
        },
      },
    },
  };
  return (
    <div className="chart-container">
      <h2 style={{ color: "white", textAlign: "center" }}>
        <b>Total Salary:</b>{" "}
        <span style={{ color: "#7be650" }}>
          {salary === "" ? setLocaleString(0) : setLocaleString(salary)}
        </span>
      </h2>
      <div className="chart-wrapper">
        <Line data={chartData} options={options} />
      </div>
    </div>
  );
}
export default LineChart;
