import React from "react";

const Issue = ({ data }) => {
  const checkCostPrice = () => {
    let cost = data?.issue?.costPrice;
    let price = data?.issue?.techPrice;
    let expectedCost = price * 2;
    let percentageDifference =
      Math.abs((cost - expectedCost) / expectedCost) * 100;

    if (cost >= expectedCost) {
      return `You are doing fine! +${percentageDifference.toFixed(2)}%`;
    } else {
      return `You are doing bad by ${percentageDifference.toFixed(2)}%`;
    }
  };
  return (
    <dl className="max-w-md text-gray-900 divide-y divide-gray-200 dark:text-white dark:divide-gray-700 mt-6">
      <div className="flex flex-col py-3">
        <dt className="mb-1 text-gray-500 md:text-lg dark:text-gray-400">
          NTE Option
        </dt>
        <dd className="text-lg font-semibold">
          {data?.nteOption ? data?.nteOption : "Not assigned"}
        </dd>
      </div>

      <div className="flex flex-col py-3">
        <dt className="mb-1 text-gray-500 md:text-lg dark:text-gray-400">
          Hourly rate
        </dt>
        <dd className="text-lg font-semibold">
          {data?.hourlyRate ? data?.hourlyRate : "Not assigned"}
        </dd>
      </div>

      <div className="flex flex-col py-3">
        <dt className="mb-1 text-gray-500 md:text-lg dark:text-gray-400">
          Trip
        </dt>
        <dd className="text-lg font-semibold">
          {data?.trip ? data?.trip : "Not assigned"}
        </dd>
      </div>

      <div className="flex flex-col py-3">
        <dt className="mb-1 text-gray-500 md:text-lg dark:text-gray-400">
          ETA
        </dt>
        <dd className="text-lg font-semibold">
          {data?.eta ? data?.eta : "Not assigned"}
        </dd>
      </div>
      {data.emergencyHourlyRate && (
        <div className="flex flex-col py-3">
          <dt className="mb-1 text-gray-500 md:text-lg dark:text-gray-400">
            Emergency Hourly Rate
          </dt>
          <dd className="text-lg font-semibold">
            {data?.eta ? data?.emergencyEta : "Not assigned"}
          </dd>
        </div>
      )}
    </dl>
  );
};

export default Issue;
