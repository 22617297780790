import React, { useState } from "react";
import TableLoading from "./Loading/TableLoading";
import AddUserModal from "./Modals/AddUserModal";
import { deleteUser } from "../API";
import { toast } from "react-toastify";
import UpdateUserModal from "./Modals/UpdateUserModal";

const UserTable = ({ tableHeader, tableData, loading, setSearch }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [updateUserModal, setUpdateUserModal] = useState(false);
  const [userData, setUserData] = useState({});

  const handleDeleteUser = (id) => {
    deleteUser(id).then((res) => {
      if (res.success) {
        toast.success("The user has been Deleted");
        window.location.reload();
      } else {
        toast.error("Something went wrong! Please contact the support.");
      }
    });
  };

  return (
    <>
      {loading ? (
        <TableLoading />
      ) : (
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg mt-4">
          <div className="flex items-center justify-between pb-4 bg-white dark:bg-gray-900">
            <label htmlFor="table-search" className="sr-only">
              Search
            </label>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <svg
                  className="w-5 h-5 text-gray-500 dark:text-gray-400"
                  aria-hidden="true"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </div>
              <input
                type="text"
                id="table-search-users"
                className="block p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Search for users"
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
            <div className="flex items-center">
              <button
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                onClick={() => setIsOpen(true)}
              >
                Add User
              </button>
            </div>
          </div>
          <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                {tableHeader?.map((item, index) => (
                  <th scope="col" className="px-6 py-3" key={index}>
                    <div className="flex items-center">
                      {item}
                      {item === "Name" ||
                        item === "Location" ||
                        (item === "Created At" && (
                          <button type="button">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="w-3 h-3 ml-1"
                              aria-hidden="true"
                              fill="currentColor"
                              viewBox="0 0 320 512"
                            >
                              <path d="M27.66 224h264.7c24.6 0 36.89-29.78 19.54-47.12l-132.3-136.8c-5.406-5.406-12.47-8.107-19.53-8.107c-7.055 0-14.09 2.701-19.45 8.107L8.119 176.9C-9.229 194.2 3.055 224 27.66 224zM292.3 288H27.66c-24.6 0-36.89 29.77-19.54 47.12l132.5 136.8C145.9 477.3 152.1 480 160 480c7.053 0 14.12-2.703 19.53-8.109l132.3-136.8C329.2 317.8 316.9 288 292.3 288z" />
                            </svg>
                          </button>
                        ))}
                    </div>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {tableData?.map((data, index) => {
                const date = new Date(data?.updatedAt);
                const options = {
                  hour12: true,
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                  minute: "2-digit",
                  hour: "numeric",
                };

                const localTime = date.toLocaleTimeString("en-US", options);

                const loginTime = new Date(data?.updatedAt);
                const currentTime = new Date();
                const diffMs = currentTime - loginTime;
                const diffSecs = Math.round(diffMs / 1000);
                const hours = Math.floor(diffSecs / 3600);
                const minutes = Math.floor((diffSecs % 3600) / 60);
                const seconds = diffSecs % 60;
                return (
                  <tr
                    className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                    key={data._id}
                  >
                    <th
                      scope="row"
                      className="flex items-center px-6 py-4 text-gray-900 whitespace-nowrap dark:text-white"
                    >
                      <img
                        className="w-10 h-10 rounded-full"
                        src={data?.profileImage}
                        alt="Jese image"
                      />
                      <div className="pl-3">
                        <div className="text-base font-semibold">
                          {data?.name}
                        </div>
                        <div className="font-normal text-gray-500">
                          {data?.email}
                        </div>
                      </div>
                    </th>
                    <td className="px-6 py-4">{data?.role?.name}</td>
                    <td className="px-6 py-4">{localTime}</td>
                    <td className="px-6 py-4">
                      {hours} : {minutes} : {seconds}
                    </td>
                    <td className="px-6 py-4">
                      <div className="flex items-center">
                        {data.isOnline ? (
                          <>
                            <div className="h-2.5 w-2.5 rounded-full bg-green-500 mr-2"></div>{" "}
                          </>
                        ) : (
                          <>
                            <div className="h-2.5 w-2.5 rounded-full bg-red-500 mr-2"></div>{" "}
                          </>
                        )}

                        {data.isOnline ? "Online" : "Offline"}
                      </div>
                    </td>
                    <td className="px-6 py-4">
                      <button
                        className="mx-2 bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                        onClick={() => {
                          setUserData(data);
                          setUpdateUserModal(true);
                        }}
                      >
                        Edit
                      </button>
                      <button
                        className="mx-2 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                        onClick={() => handleDeleteUser(data._id)}
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}

      {isOpen && <AddUserModal isOpen={isOpen} setIsopen={setIsOpen} />}
      {updateUserModal && (
        <UpdateUserModal
          isOpen={updateUserModal}
          setIsopen={setUpdateUserModal}
          data={userData}
        />
      )}
    </>
  );
};

export default UserTable;
