import React, { useEffect, useState } from "react";
import AccountantRequestCallTable from "../../components/AccountantRequestCallTable";
import { getRequestTechByAccountant, searchAccountant } from "../../API";
import { useDispatch, useSelector } from "react-redux";
import {
  setRequests,
  addRequest,
  updateRequest,
} from "../../redux/reducers/requestReducer";
import {
  resevedStatus,
  updateReceiveStatus,
} from "../../components/socket/StatusSocket";
import { logoutReducer } from "../../redux/reducers/authReducer";

const AccountantRequestedCalls = () => {
  const [loading, setLoading] = useState(false);
  const [searchKey, setSearchKey] = useState("Select Filter");
  const [noDataFoundShow, setNoDataFoundShow] = useState(false);
  const { sideBar } = useSelector((state) => state.setSideBar);
  const [totals, setTotals] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const dispatch = useDispatch();

  const handleGetRequestData = () => {
    setLoading(true);
    getRequestTechByAccountant(currentPage, 10).then((res) => {
      if (res.success) {
        dispatch(setRequests(res.requestTech));
        setTotals({
          requestTechTotal: res.requestTechTotal,
        });
        setTotalPages(res.totalPages);
        setLoading(false);
      } else {
        setNoDataFoundShow(true);
        setLoading(false);
        if (!res?.message === "The token is invalid or expired") {
          dispatch(logoutReducer());
        }
      }
    });
  };

  useEffect(() => {
    handleGetRequestData();
    resevedStatus(dispatch, addRequest);
    updateReceiveStatus(dispatch, updateRequest);
  }, []);

  const { requests } = useSelector((state) => state.requests);
  const tableHeader = [
    "#",
    "Name",
    "Location",
    "Work Order Number",
    "Created At",
    "Time To Arrive",
    "Status",
    "Action",
  ];

  const handleSearch = (search) => {
    if (searchKey === "name" || searchKey === "workOrderNumber") {
      searchAccountant(searchKey, search)
        .then((res) => {
          if (res.success) {
            dispatch(setRequests(res.requestCalls));
          } else {
            setNoDataFoundShow(true);
            if (res === "The token is invalid or expired") {
              dispatch(logoutReducer());
            }
          }
        })
        .catch((error) => {
          console.error("Error searching requested calls:", error);
          setNoDataFoundShow(true);
        });
    } else {
      // Handle other search keys or cases here
    }
  };
  return (
    <>
      <div className={`p-4 ${sideBar && "sm:ml-64"}`}>
        <div className="p-4 rounded-lg mt-14">
          <span className="text-2xl font-bold text-gray-900 dark:text-white mb-4">
            Accountant Requested Calls
          </span>
          <AccountantRequestCallTable
            tableHeader={tableHeader}
            tableData={requests}
            loading={loading}
            handleSearch={handleSearch}
            setSearchKey={setSearchKey}
            searchKey={searchKey}
            noDataFoundShow={noDataFoundShow}
            totals={totals}
            totalPages={totalPages}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </div>
      </div>
    </>
  );
};

export default AccountantRequestedCalls;
