import { createSlice } from "@reduxjs/toolkit";

export const paymentRequests = createSlice({
  name: "paymentRequest",
  initialState: {
    paymentRequest: [],
    paymentRequestData: {},
  },

  reducers: {
    setpaymentRequest: (state, action) => {
      state.paymentRequest = action.payload;
    },
    addpaymentRequest: (state, action) => {
      state.paymentRequest = [
        ...state.paymentRequest,
        action.payload,
      ].reverse();
    },

    setpaymentRequestData: (state, action) => {
      state.paymentRequestData = action.payload;
    },

    updatepaymentRequestData: (state, action) => {
      state.paymentRequestData = {
        ...state.paymentRequestData,
        ...action.payload,
      };
    },
  },
});

export const {
  setpaymentRequest,
  addpaymentRequest,
  setpaymentRequestData,
  updatepaymentRequestData,
} = paymentRequests.actions;

export default paymentRequests.reducer;
