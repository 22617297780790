import io from "socket.io-client";

const timeSocket = io(`${process.env.REACT_APP_SOCKET_API}/timer-socket`, {
  autoConnect: false,
}); // replace with your server URL

const resevedTimer = (dispatch, updateRequest) => {
  return timeSocket.on("timeToArrive", (data) => {
    dispatch(updateRequest(data));
  });
};

const getRequestDataTimeout = (data) => {
  return timeSocket.emit("getTimeData", data);
};

export { resevedTimer, timeSocket, getRequestDataTimeout };
