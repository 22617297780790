import React from "react";
import { useSelector } from "react-redux";

const Overview = ({ data }) => {
  const { role } = useSelector((state) => state.auth);
  return (
    <dl className="max-w-md text-gray-900 divide-y divide-gray-200 dark:text-white dark:divide-gray-700 mt-5">
      <div className="flex flex-col pb-3 pt-4">
        <dt className="mb-1 text-gray-500 md:text-lg dark:text-gray-400">
          Client name
        </dt>
        <dd className="text-lg font-semibold">{data?.name}</dd>
      </div>
      <div className="flex flex-col py-3">
        <dt className="mb-1 text-gray-500 md:text-lg dark:text-gray-400">
          Location
        </dt>
        <dd className="text-lg font-semibold">{data?.location}</dd>
      </div>
      <div className="flex flex-col py-3">
        <dt className="mb-1 text-gray-500 md:text-lg dark:text-gray-400">
          Description
        </dt>
        <dd className="text-lg font-semibold">{data?.description}</dd>
      </div>
      {role === "Dispatchers" ? (
        <></>
      ) : (
        <>
          <div className="flex flex-col py-3">
            <dt className="mb-1 text-gray-500 md:text-lg dark:text-gray-400">
              Email Address
            </dt>
            <dd className="text-lg font-semibold">{data?.email}</dd>
          </div>
          <div className="flex flex-col py-3">
            <dt className="mb-1 text-gray-500 md:text-lg dark:text-gray-400">
              Phone number
            </dt>
            <dd className="text-lg font-semibold">{data?.phone}</dd>
          </div>
        </>
      )}
    </dl>
  );
};

export default Overview;
