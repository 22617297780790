import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Technician from "./tabs/Technician";
import Overview from "./tabs/Overview";
import Documents from "./tabs/Documents";
import PaymentRequest from "./tabs/PaymentRequest";
import DetailsLoading from "../../components/Loading/DetailsLoading";
import {
  getAllCosts,
  getPaymentRequest,
  getRequestById,
  updateStatusToConfirmed,
} from "../../API";
import Issue from "./tabs/Issue";
import Proposal from "./tabs/Proposal";
import { useSelector, useDispatch } from "react-redux";
import CheckinModal from "../../components/Modals/CheckinModal";
import CheckoutModal from "../../components/Modals/CheckoutModal";
import { getRequestDataTimeout } from "../../components/socket/timerSocket";
import CancelJobModal from "../../components/Modals/CancelJobModal";
import UpdateTechModal from "../../components/Modals/UpdateTechModal";
import AddCostsModal from "../../components/Modals/AddCostsModal";

import {
  updateRequest,
  setRequestData,
} from "../../redux/reducers/requestReducer";
import { toast } from "react-toastify";
import Chat from "./tabs/Chat";
import Costs from "./tabs/Costs";
import {
  addNTEPrice,
  setCosts,
  setTotal,
} from "../../redux/reducers/costReducer";
import AddNTEModel from "../../components/Modals/AddNTEModel";
import {
  ACCOUNTANT_ROLE,
  DISPATCHER_ROLE,
  MANAGER_ROLE,
  TEAMLEADER_ROLE,
  setLocaleString,
} from "../../GlobalFunctions";
import AddTechModal from "../../components/Modals/AddTechModal";
import DeadLineModal from "../../components/Modals/DeadLineModal";
import EditJob from "../../components/Modals/EditJob";
import { setpaymentRequestData } from "../../redux/reducers/paymentRequestReducer";

const RequestDetails = () => {
  const [costTab, setCostTab] = useState(false);
  const [overviewTab, setOverviewTab] = useState(true);
  const [techInfoTab, setTechInfoTab] = useState(false);
  const [documentsTab, setDocumentsTab] = useState(false);
  const [proposalTab, setProposalTab] = useState(false);
  const [chatTab, setChatTab] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [checkoutOpen, setCheckoutOpen] = useState(false);
  const [PaymentTab, setPaymentTab] = useState(false);
  const [cancelJob, setCancelJob] = useState(false);
  const [editTech, setEditTech] = useState(false);
  const [deadLine, setAddDeadLine] = useState(false);
  const [costsModal, setCostsModal] = useState(false);
  const [addNteModel, setAddNteModel] = useState(false);
  const [addNewTechModal, setAddNewTechModal] = useState(false);
  const [editJob, setEditJob] = useState(false);

  const { role } = useSelector((state) => state.auth);
  const { total, NTEPrice } = useSelector((state) => state.cost);
  const { sideBar } = useSelector((state) => state.setSideBar);

  const { requestData } = useSelector((state) => state.requests);
  const { paymentRequestData } = useSelector((state) => state.paymentRequest);
  const { id } = useParams();
  const dispatch = useDispatch();

  const handleOverviewTab = () => {
    setOverviewTab(true);
    setTechInfoTab(false);
    setDocumentsTab(false);
    setProposalTab(false);
    setChatTab(false);
    setCostTab(false);
    setPaymentTab(false);
  };
  const handleTechInfoTab = () => {
    setTechInfoTab(true);
    setOverviewTab(false);
    setDocumentsTab(false);
    setProposalTab(false);
    setChatTab(false);
    setCostTab(false);
    setPaymentTab(false);
  };
  const handleDocumentTab = () => {
    setDocumentsTab(true);
    setOverviewTab(false);
    setTechInfoTab(false);
    setProposalTab(false);
    setChatTab(false);
    setCostTab(false);
    setPaymentTab(false);
  };
  const handleProposalTab = () => {
    setProposalTab(true);
    setDocumentsTab(false);
    setOverviewTab(false);
    setTechInfoTab(false);
    setChatTab(false);
    setCostTab(false);
    setPaymentTab(false);
  };
  const handleChatTab = () => {
    setChatTab(true);
    setProposalTab(false);
    setDocumentsTab(false);
    setOverviewTab(false);
    setTechInfoTab(false);
    setCostTab(false);
    setPaymentTab(false);
  };
  const handleCostTab = () => {
    setCostTab(true);
    setOverviewTab(false);
    setTechInfoTab(false);
    setDocumentsTab(false);
    setProposalTab(false);
    setChatTab(false);
    setPaymentTab(false);
  };
  const handlePaymentTab = () => {
    setPaymentTab(true);
    setCostTab(false);
    setOverviewTab(false);
    setTechInfoTab(false);
    setDocumentsTab(false);
    setProposalTab(false);
    setChatTab(false);
  };

  const handleGetCallById = () => {
    setLoading(true);
    getRequestById(id).then((res) => {
      if (res.success) {
        dispatch(setRequestData(res.requestTech));
      }
    });

    getAllCosts(id).then((result) => {
      if (result.success) {
        dispatch(setTotal(result.total));
        dispatch(setCosts(result.costs));
        dispatch(addNTEPrice(result?.NTEData?.NTEPrice));
        setLoading(false);
      }
    });

    getPaymentRequest(id).then((result) => {
      if (result.paymentRequests) {
        dispatch(setpaymentRequestData(result.paymentRequests));
      }
    });
  };

  const handleConfirm = () => {
    updateStatusToConfirmed(id).then((result) => {
      if (result.success) {
        toast.success("Update Status to Confirmed!");
        dispatch(updateRequest(result.request));
        getRequestDataTimeout(requestData);
      }
    });
  };

  useEffect(() => {
    handleGetCallById();
  }, []);

  return (
    <>
      {loading ? (
        <DetailsLoading />
      ) : (
        <>
          <div className={`p-4 ${sideBar && "sm:ml-64"}`}>
            <div className="p-4 rounded-lg mt-14">
              <span className="text-2xl font-bold text-gray-900 dark:text-white">
                Requested Calls Details
              </span>
              <div className="grid grid-rows-1 grid-flow-col gap-4 mt-4">
                <div className="row-span-3 flex flex-col rounded bg-gray-50 dark:bg-gray-800 p-4">
                  <span className="text-2xl font-semibold text-gray-900 dark:text-white">
                    Details - WO# {requestData?.workOrderNumber}
                  </span>
                  <dl className="max-w-md text-gray-900 divide-y divide-gray-200 dark:text-white dark:divide-gray-700 w-48">
                    <div className="flex flex-col pb-3 pt-4">
                      <dt className="mb-1 text-gray-500 md:text-lg dark:text-gray-400">
                        Company name
                      </dt>
                      <dd className="text-lg font-semibold">
                        {requestData?.company?.name}
                      </dd>
                    </div>
                    <div className="flex flex-col py-3">
                      <dt className="mb-1 text-gray-500 md:text-lg dark:text-gray-400">
                        Manager responsible
                      </dt>
                      <dd className="text-lg font-semibold">
                        {requestData?.manger?.name}
                      </dd>
                    </div>
                    <div className="flex flex-col py-3">
                      <dt className="mb-1 text-gray-500 md:text-lg dark:text-gray-400">
                        Team leader responsible
                      </dt>
                      <dd className="text-lg font-semibold">
                        {requestData?.teamLead?.name}
                      </dd>
                    </div>
                    <div className="flex flex-col py-3">
                      <dt className="mb-1 text-gray-500 md:text-lg dark:text-gray-400">
                        Dispatcher responsible
                      </dt>
                      <dd className="text-lg font-semibold">
                        {requestData?.dispatcher?.name}
                      </dd>
                    </div>
                  </dl>
                </div>
                <div className="col-span-6 mb-4 rounded bg-gray-50 dark:bg-gray-800 p-4">
                  <div className="text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:text-gray-400 dark:border-gray-700 mb-6">
                    <ul className="flex flex-wrap -mb-px">
                      <li className="mr-2">
                        <button
                          onClick={handleOverviewTab}
                          type="button"
                          className={`inline-block p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 ${
                            overviewTab
                              ? "active dark:text-blue-500 dark:border-blue-500"
                              : ""
                          }`}
                        >
                          Overview
                        </button>
                      </li>
                      <li className="mr-2">
                        <button
                          onClick={handleTechInfoTab}
                          type="button"
                          className={`inline-block p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 ${
                            techInfoTab
                              ? "active dark:text-blue-500 dark:border-blue-500"
                              : ""
                          }`}
                        >
                          Technician Information
                        </button>
                      </li>
                      <li className="mr-2">
                        <button
                          onClick={handleCostTab}
                          type="button"
                          className={`inline-block p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 ${
                            costTab
                              ? "active dark:text-blue-500 dark:border-blue-500"
                              : ""
                          }`}
                        >
                          Costs
                        </button>
                      </li>
                      <li>
                        <button
                          onClick={handleProposalTab}
                          type="button"
                          className={`inline-block p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 ${
                            proposalTab
                              ? "active dark:text-blue-500 dark:border-blue-500"
                              : ""
                          }`}
                        >
                          Proposal NTE
                        </button>
                      </li>
                      <li>
                        <button
                          onClick={handleDocumentTab}
                          type="button"
                          className={`inline-block p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 ${
                            documentsTab
                              ? "active dark:text-blue-500 dark:border-blue-500"
                              : ""
                          }`}
                        >
                          Documents
                        </button>
                      </li>
                      <li>
                        <button
                          onClick={handleChatTab}
                          type="button"
                          className={`inline-block p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 ${
                            chatTab
                              ? "active dark:text-blue-500 dark:border-blue-500"
                              : ""
                          }`}
                        >
                          Annotations
                        </button>
                      </li>

                      <li className="mr-2">
                        <button
                          onClick={handlePaymentTab}
                          type="button"
                          className={`inline-block p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 ${
                            PaymentTab
                              ? "active dark:text-blue-500 dark:border-blue-500"
                              : ""
                          }`}
                        >
                          Payment Request
                        </button>
                      </li>
                    </ul>
                  </div>
                  {overviewTab && (
                    <>
                      <div className="flex justify-between items-center">
                        <span className="text-2xl font-semibold text-gray-900 dark:text-white">
                          Client Details - {requestData?.status}
                        </span>

                        {role === MANAGER_ROLE && (
                          <div>
                            <button
                              className="mx-2 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                              onClick={() => setCancelJob(true)}
                            >
                              Cancel Job
                            </button>
                            <button
                              className="mx-2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline justify-end"
                              onClick={() => setEditJob(true)}
                            >
                              Edit Job
                            </button>
                          </div>
                        )}
                      </div>

                      <div className="grid grid-cols-2 gap-2">
                        <div>
                          <Overview data={requestData} />
                        </div>
                        <div>
                          <Issue data={requestData} />
                        </div>
                      </div>
                    </>
                  )}

                  {techInfoTab && (
                    <>
                      <div className="flex justify-between items-center">
                        <div>
                          <span className="text-2xl font-semibold text-gray-900 dark:text-white">
                            Technician Details
                          </span>
                        </div>

                        {role !== ACCOUNTANT_ROLE ? (
                          <>
                            {role == TEAMLEADER_ROLE ? (
                              <button
                                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                                onClick={handleConfirm}
                              >
                                Confirm
                              </button>
                            ) : (
                              ""
                            )}
                          </>
                        ) : (
                          <></>
                        )}

                        <>
                          {role === DISPATCHER_ROLE || role === MANAGER_ROLE ? (
                            <div className="flex">
                              <button
                                className="mx-2 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                                onClick={() => setCheckoutOpen(true)}
                              >
                                Check out
                              </button>
                              <button
                                className="mx-2 bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                                onClick={() => setIsOpen(true)}
                              >
                                Check in
                              </button>
                              <button
                                className={`mx-2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline`}
                                onClick={() => setAddNewTechModal(true)}
                              >
                                Add Technician
                              </button>
                              <button
                                className={`mx-2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline ${
                                  requestData?.status !== "Confirm"
                                    ? ""
                                    : "opacity-50 cursor-not-allowed pointer-events-none"
                                }`}
                                onClick={() => setEditTech(true)}
                              >
                                Edit
                              </button>
                              <button
                                className={`mx-2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline`}
                                onClick={() => setAddDeadLine(true)}
                              >
                                Add Deadline
                              </button>
                            </div>
                          ) : (
                            ""
                          )}
                        </>
                      </div>
                      <Technician data={requestData} />
                    </>
                  )}

                  {costTab && (
                    <>
                      <div className="flex justify-between items-center flex-end">
                        <div className="flex flex-col items-baseline flex-end">
                          <h4 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                            Total Costs Price: {setLocaleString(total)}
                          </h4>
                          <h4 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                            NTE:{" "}
                            {setLocaleString(isNaN(NTEPrice) ? 0 : NTEPrice)}
                          </h4>
                        </div>
                        <div>
                          <button
                            className={`mx-2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline ${
                              total === 0
                                ? "opacity-50 cursor-not-allowed pointer-events-none"
                                : ""
                            }`}
                            onClick={() => setAddNteModel(true)}
                          >
                            Add NTE Price
                          </button>
                          <button
                            className="mx-2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                            onClick={() => setCostsModal(true)}
                          >
                            Add Costs
                          </button>
                        </div>
                      </div>
                      <div className="grid grid-cols-1 gap-1">
                        <Costs setTotal={setTotal} />
                      </div>
                    </>
                  )}

                  {proposalTab && <Proposal data={requestData} />}
                  {documentsTab && <Documents data={requestData} />}
                  {chatTab && <Chat />}

                  {PaymentTab && (
                    <PaymentRequest
                      data={paymentRequestData}
                      requestData={requestData}
                      requestCallId={id}
                      NTEData={NTEPrice}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {isOpen && <CheckinModal isOpen={isOpen} setIsopen={setIsOpen} />}
      {checkoutOpen && (
        <CheckoutModal
          isOpen={checkoutOpen}
          setIsopen={setCheckoutOpen}
          requestData={requestData}
        />
      )}

      {cancelJob && (
        <CancelJobModal
          isOpen={cancelJob}
          setIsopen={setCancelJob}
          data={requestData}
        />
      )}

      {editJob && (
        <EditJob isOpen={editJob} setIsopen={setEditJob} data={requestData} />
      )}

      {editTech && (
        <UpdateTechModal
          isOpen={editTech}
          setIsopen={setEditTech}
          data={requestData}
        />
      )}

      {deadLine && (
        <DeadLineModal
          isOpen={deadLine}
          setIsopen={setAddDeadLine}
          data={requestData}
        />
      )}

      {costsModal && (
        <AddCostsModal
          isOpen={costsModal}
          setIsopen={setCostsModal}
          data={requestData}
        />
      )}

      {addNteModel && (
        <AddNTEModel isOpen={addNteModel} setIsopen={setAddNteModel} />
      )}

      {addNewTechModal && (
        <AddTechModal
          isOpen={addNewTechModal}
          setIsopen={setAddNewTechModal}
          RequestTechSchemaId={id}
        />
      )}
    </>
  );
};

export default RequestDetails;
