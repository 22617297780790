import io from "socket.io-client";

const notificationSocket = io(
  `${process.env.REACT_APP_SOCKET_API}/notification-socket`,
  {
    autoConnect: false,
  }
);

const joinUser = (userId) => {
  notificationSocket.emit("join", userId);
};

const sendNotification = (data) => {
  notificationSocket.emit("sendNotification", data);
};

const getAllNotifications = (
  dispatch,
  setNotification,
  addNotificationNumber
) => {
  return notificationSocket.on("allNotifications", (data) => {
    dispatch(setNotification(data.notification));
    dispatch(addNotificationNumber(data.notificationNumber));
  });
};

export { notificationSocket, sendNotification, getAllNotifications, joinUser };
