import React from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { slectedATech } from "../../../API";
import { updateRequestData } from "../../../redux/reducers/requestReducer";

const Technician = ({ data }) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { requestData } = useSelector((state) => state.requests);
  const { role } = useSelector((state) => state.auth);
  const renderStars = () => {
    const rating = data?.tech?.rating || 0;
    const stars = [];

    for (let i = 0; i < 5; i++) {
      const starIcon = (
        <svg
          key={i}
          aria-hidden="true"
          className={`w-5 h-5 ${
            i < rating ? "text-yellow-400" : "text-gray-300 dark:text-gray-500"
          }`}
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <title>Star {i + 1}</title>
          <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
        </svg>
      );

      stars.push(starIcon);
    }

    return stars;
  };
  return (
    <dl className="grid grid-cols-2 gap-4 text-gray-900 divide-y divide-gray-200 dark:text-white dark:divide-gray-700 mt-5">
      {role === "Dispatchers" && (
        <div className="flex flex-col py-3">
          <select
            name="hours"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
            required
            onChange={(e) => {
              slectedATech(id, e.target.value).then((result) => {
                if (result.success) {
                  dispatch(updateRequestData(result.tech));
                }
              });
            }}
          >
            <option selected disabled>
              Select Technician
            </option>
            {requestData?.technichines.map((item) => (
              <option
                value={item._id}
                key={item._id}
                selected={data?.tech?._id === item._id}
              >
                {item.name}
              </option>
            ))}
          </select>
        </div>
      )}
      <div className="flex flex-col pb-3 pt-4">
        <dt className="mb-1 text-gray-500 md:text-lg dark:text-gray-400">
          Technician name
        </dt>
        <dd className="text-lg font-semibold">{data?.tech?.name}</dd>
      </div>
      <div className="flex flex-col py-3">
        <dt className="mb-1 text-gray-500 md:text-lg dark:text-gray-400">
          Phone number
        </dt>
        <dd className="text-lg font-semibold">{data?.tech?.phone}</dd>
      </div>
      <div className="flex flex-col py-3">
        <dt className="mb-1 text-gray-500 md:text-lg dark:text-gray-400">
          Payment Method
        </dt>
        <dd className="text-lg font-semibold">{data?.tech?.paymentMethod}</dd>
      </div>
      <div className="flex flex-col py-3">
        <dt className="mb-1 text-gray-500 md:text-lg dark:text-gray-400">
          Review technician
        </dt>
        <dd className="text-lg font-semibold">
          <div className="flex items-center flex-row">
            {renderStars()}
            <p className="ml-2 text-sm font-medium text-gray-500 dark:text-gray-400">
              {data?.tech?.rating} out of 5
            </p>
          </div>

          <p className="ml-2 text-l font-medium mt-5">{data?.tech?.review}</p>
        </dd>
      </div>

      {data?.tech?.timeInSiteFrom && data?.tech?.timeInSiteTo && (
        <div className="flex flex-col py-3">
          <dt className="mb-1 text-gray-500 md:text-lg dark:text-gray-400">
            Deadline
          </dt>
          <span className="font-semibold">Date From:</span>
          <dd className="text-md">{data?.tech?.timeInSiteFrom}</dd>
          <span className="font-semibold">Date To:</span>
          <dd className="text-md">{data?.tech?.timeInSiteTo}</dd>
        </div>
      )}
      <div className="flex flex-col py-3">
        <dt className="mb-1 text-gray-500 md:text-lg dark:text-gray-400">
          State
        </dt>
        <dd className="text-lg font-semibold">{data?.tech?.state}</dd>
      </div>
      <div className="flex flex-col py-3">
        <dt className="mb-1 text-gray-500 md:text-lg dark:text-gray-400">
          Country
        </dt>
        <dd className="text-lg font-semibold">{data?.tech?.country}</dd>
      </div>
      <div className="flex flex-col py-3">
        <dt className="mb-1 text-gray-500 md:text-lg dark:text-gray-400">
          Zip code
        </dt>
        <dd className="text-lg font-semibold">{data?.tech?.zip}</dd>
      </div>
    </dl>
  );
};

export default Technician;
