import { createSlice } from "@reduxjs/toolkit";

export const auth = createSlice({
  name: "auth",
  initialState: {
    isLoggedIn: sessionStorage.getItem("token") ? true : false,
    token: sessionStorage.getItem("token") || "",
    role: sessionStorage.getItem("role") || "",
    userId: sessionStorage.getItem("userId") || "",
    currentUser: JSON.parse(sessionStorage.getItem("currentUser")) || {},
  },

  reducers: {
    loginReducer: (state, action) => {
      state.isLoggedIn = true;
      state.token = action.payload.token;
      state.role = action.payload.user.role.name;
      state.userId = action.payload.userId;
      state.currentUser = action.payload.user;
      sessionStorage.setItem("token", action.payload.token);
      sessionStorage.setItem("role", action.payload.user.role.name);
      sessionStorage.setItem("userId", action.payload.userId);
      sessionStorage.setItem(
        "currentUser",
        JSON.stringify(action.payload.user)
      );
    },
    logoutReducer: (state) => {
      state.isLoggedIn = false;
      state.token = "";
      state.role = "";
      state.userId = "";
      sessionStorage.clear();
    },
  },
});

export const { loginReducer, logoutReducer } = auth.actions;

export default auth.reducer;
