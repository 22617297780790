import {
  Routes,
  Route,
  useLocation,
  useNavigate,
  Link,
} from "react-router-dom";
// Layouts
import Navbar from "./layouts/Navbar";
import { useDispatch, useSelector } from "react-redux";
// Pages
import Login from "./pages/auth/Login";
import ForgetPassword from "./pages/auth/ForgetPassword";
import ResetPassword from "./pages/auth/ResetPassword";
import ManagerRequestedCalls from "./pages/manager/RequestedCalls";
import AccountantRequestedCalls from "./pages/accountant/RequestedCalls";
import InvoiverRequestedCalls from "./pages/invoicer";
import DispatcherRequestedCalls from "./pages/dispatcher/RequestedCalls";
import User from "./pages/manager/User";
import CompletedRequests from "./pages/manager/CompletedRequests";
import RequestDetails from "./pages/details/RequestDetails";
import { ToastContainer, toast } from "react-toastify";
import RequestedCalls from "./pages/teamleader/RequestedCalls";
import DispatcherRequestedCallsDetails from "./pages/details/RequestDetails";
import { statusSocket } from "./components/socket/StatusSocket";
import { useEffect, useState } from "react";
import { timeSocket } from "./components/socket/timerSocket";
import { chatSocket } from "./components/socket/chatSocket";
import Settings from "./pages/settings/Settings";
import { messaging } from "./firebaseConfig";
import { getToken, onMessage, getMessaging } from "firebase/messaging";
import { ForgetPasswordConfirmation } from "./pages/auth/ForgetPasswordConfirmation";
import Commission from "./pages/commission/Commission";
import {
  addNotification,
  addNotificationNumber,
  setNotification,
} from "./redux/reducers/notificationReducer";
import { userSocket } from "./components/socket/userStatus";
import {
  getAllNotifications,
  joinUser,
  notificationSocket,
} from "./components/socket/notificationSocket";
import { getNotification } from "./API";
import Admin from "./pages/Admin";
import Technicians from "./pages/manager/Technicians";
import {
  ACCOUNTANT_ROLE,
  ADMIN_ROLE,
  DISPATCHER_ROLE,
  INVOICER_ROLE,
  MANAGER_ROLE,
  TEAMLEADER_ROLE,
} from "./GlobalFunctions";
import TechniciansDispatcher from "./pages/dispatcher/TechniciansDispatcher";
import PaymentRequests from "./pages/paymentRequest";

const App = () => {
  const { isLoggedIn, role, userId, currentUser } = useSelector(
    (state) => state.auth
  );
  const [fcmToken, setFCMToken] = useState("");
  const [message, setMessage] = useState("");
  const [paymentMethodShow, setPaymentMethodShow] = useState(false);

  const dispatch = useDispatch();
  // Get the navigation function and current location
  const navigate = useNavigate();
  const location = useLocation();

  const toastMessage = (link, message) => (
    <div>
      <Link to={link}>{message}</Link>
    </div>
  );

  const handlegetFCMToken = () => {
    getToken(messaging, {
      vapidKey:
        "BBXnH1fveZdIBydO9ioCCGnuDgYGtHF9W2Hmg9-YthziirLlJTudtgt5bUv93YC05Dyp_lOcZVTMaN-9qut0zLo",
    })
      .then((currentToken) => {
        if (currentToken) {
          setFCMToken(currentToken);
        } else {
          Notification.requestPermission().then((permission) => {
            if (permission === "granted") {
              toast("Notification permission granted.");
            }
          });
        }
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  const getMessage = () => {
    const messaging = getMessaging();
    onMessage(messaging, (payload) => {
      const { title } = payload?.notification;
      const body = JSON.parse(payload?.notification?.body);
      const currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
      const role = currentUser?.role?.name;
      if (role === "TeamLead") {
        if (body?.teamLead?.fcmToken === currentUser?.fcmToken) {
          toast.success(
            toastMessage(
              `${payload?.fcmOptions?.link}/team-leader/details/request-details/${body._id}`,
              title
            ),
            {
              autoClose: false, // Disable auto-close
            }
          );
        }
      } else if (role === "Dispatchers") {
        if (body?.dispatcher?.fcmToken === currentUser?.fcmToken) {
          toast.success(title, {
            autoClose: false, // Disable auto-close
          });
        }
      }
    });
  };

  getNotification().then((res) => {
    if (res.success) {
      dispatch(setNotification(res.notifications));
      dispatch(addNotificationNumber(res.notificationNumber));
    }
  });

  useEffect(() => {
    if (role === ACCOUNTANT_ROLE) {
      setPaymentMethodShow(true);
    }

    if (role === TEAMLEADER_ROLE) {
      setPaymentMethodShow(true);
    }

    if (currentUser.email === "jay@hawksfs.com") {
      setPaymentMethodShow(true);
    }

    if (currentUser.email === "moanayaser6@gmail.com") {
      setPaymentMethodShow(true);
    }
    handlegetFCMToken();
    getMessage();
    statusSocket.connect();
    timeSocket.connect();
    chatSocket.connect();
    userSocket.connect();
    notificationSocket.connect();
    joinUser(userId);
    getAllNotifications(dispatch, addNotification, addNotificationNumber);

    return () => {
      statusSocket.close();
      timeSocket.close();
      chatSocket.close();
      userSocket.close();
      notificationSocket.close();
    };
  }, []);

  useEffect(() => {
    if (!isLoggedIn) {
      if (location.pathname !== "/login") {
        navigate("/login"); // Redirect to your login page
      }
    }
  }, [isLoggedIn]);

  return (
    <>
      <ToastContainer />
      {isLoggedIn && (
        <>
          <Navbar message={message} />
          <Routes>
            {/* Manager */}
            {role === MANAGER_ROLE && (
              <>
                <Route path="/" element={<ManagerRequestedCalls />} />
                <Route path="/technicians" element={<Technicians />} />

                <Route
                  path="/manager/requested-calls"
                  element={<ManagerRequestedCalls />}
                />
                <Route path="/manager/users" element={<User />} />
                <Route
                  path="/manager/details/request-details/:id"
                  element={<RequestDetails />}
                />

                <Route
                  path="/manager/completed-requests"
                  element={<CompletedRequests />}
                />
                <Route path="/settings/:id" element={<Settings />} />
              </>
            )}

            {/* Team Leader */}
            {role === TEAMLEADER_ROLE && (
              <>
                <Route path="/" element={<RequestedCalls />} />
                <Route
                  path="/team-leader/requested-calls"
                  element={<RequestedCalls />}
                />

                <Route
                  path="/team-leader/details/request-details/:id"
                  element={<RequestDetails />}
                />
                <Route path="/settings/:id" element={<Settings />} />
              </>
            )}

            {/* Dispatcher */}

            {role === DISPATCHER_ROLE && (
              <>
                <Route
                  path="/dispatcher/requested-calls"
                  element={<DispatcherRequestedCalls />}
                />
                <Route
                  path="dispatcher/technicians"
                  element={<TechniciansDispatcher />}
                />

                <Route path="/" element={<DispatcherRequestedCalls />} />

                <Route
                  path="/dispatcher/details/request-details/:id"
                  element={<DispatcherRequestedCallsDetails />}
                />
                <Route path="/settings/:id" element={<Settings />} />
              </>
            )}

            {/* Accountant */}

            {role === ACCOUNTANT_ROLE && (
              <>
                <Route path="/" element={<AccountantRequestedCalls />} />

                <Route
                  path="/accountant/requested-calls"
                  element={<AccountantRequestedCalls />}
                />

                <Route
                  path="/accountant/details/request-details/:id"
                  element={<RequestDetails />}
                />

                <Route path="/settings/:id" element={<Settings />} />
              </>
            )}

            <Route path="/commissions" element={<Commission />} />
            <Route
              path="/request-job/details/:id"
              element={<RequestDetails />}
            />

            {/* Invoicer */}

            {role === INVOICER_ROLE && (
              <>
                <Route path="/" element={<InvoiverRequestedCalls />} />
                <Route path="/settings/:id" element={<Settings />} />

                <Route
                  path="/invoicer/requested-calls"
                  element={<InvoiverRequestedCalls />}
                />

                <Route
                  path="/invoicer/details/request-details/:id"
                  element={<RequestDetails />}
                />
              </>
            )}

            {/* Admin */}
            {role === ADMIN_ROLE && (
              <>
                <Route path="/" element={<Admin />} />

                <Route path="/admin" element={<Admin />} />

                <Route path="/settings/:id" element={<Settings />} />

                <Route
                  path="/admin/details/request-details/:id"
                  element={<RequestDetails />}
                />

                <Route
                  path="/admin/requested-calls"
                  element={<ManagerRequestedCalls />}
                />
              </>
            )}
            <Route path="/payment" element={<PaymentRequests />} />

            {/* Payment Page */}
            {paymentMethodShow && <></>}
          </Routes>
        </>
      )}
      {!isLoggedIn && (
        <Routes>
          {/* Auth */}
          <Route path="/forget-password" element={<ForgetPassword />} />
          <Route path="/reset-password/:id" element={<ResetPassword />} />
          <Route
            path="/password-confirmation/:id"
            element={<ForgetPasswordConfirmation />}
          />
          <Route path="/login" element={<Login fcmToken={fcmToken} />} />
          <Route path="/" element={<Login fcmToken={fcmToken} />} />
        </Routes>
      )}
    </>
  );
};

export default App;
