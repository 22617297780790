import { React, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { logoutReducer } from "../redux/reducers/authReducer";
import { logout } from "../API";
import { useNavigate } from "react-router-dom";
import RequestCallModal from "../components/Modals/RequestCallModal";
import Notification from "../components/Notification";
import { setSideBarOpen } from "../redux/reducers/sideBarReducer";
import { usersStatus } from "../components/socket/userStatus";
import Logo from "../images/logo.png";
import {
  ACCOUNTANT_ROLE,
  DISPATCHER_ROLE,
  MANAGER_ROLE,
  TEAMLEADER_ROLE,
} from "../GlobalFunctions";

const Navbar = ({ message }) => {
  const { token, userId, role, currentUser } = useSelector(
    (state) => state.auth
  );
  const { sideBar } = useSelector((state) => state.setSideBar);
  const [userOption, setUserOption] = useState(false);
  const [notification, setNotification] = useState(false);
  const [requestCallModal, setRequestCallModal] = useState(false);
  const { notificationNumber } = useSelector((state) => state.notification);
  const [currentDate, setCurrentTime] = useState("");
  const [paymentMethodShow, setPaymentMethodShow] = useState(false);

  const dispatch = useDispatch();
  const navigation = useNavigate();

  const handleLogout = () => {
    logout(userId, token)
      .then((res) => {
        if (res.success) {
          dispatch(logoutReducer());
          usersStatus();
          navigation("/login");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (role === ACCOUNTANT_ROLE) {
      setPaymentMethodShow(true);
    }

    if (role === TEAMLEADER_ROLE) {
      setPaymentMethodShow(true);
    }

    if (currentUser.email === "jay@hawksfs.com") {
      setPaymentMethodShow(true);
    }

    if (currentUser.email === "moanayaser6@gmail.com") {
      setPaymentMethodShow(true);
    }

    const interval = setInterval(() => {
      const date = new Date().toLocaleTimeString("en-US", {
        timeZone: "America/New_York",
        hour: "numeric",
        minute: "numeric",
      });
      setCurrentTime(date);
    }, 1000); // Update every second (1000 milliseconds)

    return () => {
      clearInterval(interval); // Clean up the interval on unmount
    };
  }, []);

  return (
    <>
      <nav className="fixed top-0 z-50 w-full bg-white border-b border-gray-200 dark:bg-gray-800 dark:border-gray-700">
        <div className="px-3 py-3 lg:px-5 lg:pl-3">
          <div className="flex items-center justify-between">
            <div className="flex items-center justify-start">
              <button
                onClick={() => dispatch(setSideBarOpen(!sideBar))}
                type="button"
                className="inline-flex items-center p-2 text-sm text-gray-500 rounded-lg hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
              >
                <span className="sr-only">Open sidebar</span>
                <svg
                  className="w-6 h-6"
                  aria-hidden="true"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    clipRule="evenodd"
                    fillRule="evenodd"
                    d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"
                  ></path>
                </svg>
              </button>
              <Link to="/" className="flex ml-2 md:mr-24">
                <img src={Logo} className="h-8 mr-3" alt="Logo" />
                <span className="self-center text-xl font-semibold sm:text-2xl whitespace-nowrap dark:text-white">
                  <span className="text-logoRedColor">MJN</span>{" "}
                  <span className="text-logoTextSecoundColor">Facility</span>
                  <span className="px-2 text-lg">{currentDate}</span>
                </span>
              </Link>
            </div>
            {role !== "Dispatchers" && role !== "TeamLead" && (
              <div className="flex items-center">
                <button
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                  onClick={() => setRequestCallModal(true)}
                >
                  Request a call
                </button>
              </div>
            )}
            <div className="flex items-center ml-3">
              <div className="mx-4" style={{ marginTop: "4px" }}>
                <button
                  id="dropdownNotificationButton"
                  data-dropdown-toggle="dropdownNotification"
                  className="flex items-center text-sm font-medium text-center text-gray-500 hover:text-gray-900 focus:outline-none dark:hover:text-white dark:text-gray-400"
                  type="button"
                  onClick={() => {
                    setNotification(!notification);
                    setUserOption(false);
                  }}
                >
                  <svg
                    className="w-6 h-6"
                    aria-hidden="true"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M10 2a6 6 0 00-6 6v3.586l-.707.707A1 1 0 004 14h12a1 1 0 00.707-1.707L16 11.586V8a6 6 0 00-6-6zM10 18a3 3 0 01-3-3h6a3 3 0 01-3 3z"></path>
                  </svg>
                  {notificationNumber !== 0 && (
                    <>
                      <div className="relative w-5 h-5 bg-red-500 border-2 border-white rounded-full -top-2 right-3 dark:border-gray-900">
                        <span className="absolute inset-0 flex items-center justify-center text-xs font-bold text-white">
                          {notificationNumber < 10 ? notificationNumber : `9+`}
                        </span>
                      </div>
                    </>
                  )}
                </button>
              </div>
              <div
                id="dropdownNotification"
                className={
                  notification === true
                    ? "absolute top-1/2 right-20 my-4 text-base list-none bg-white divide-y divide-gray-100 rounded shadow dark:bg-gray-700 dark:divide-gray-600"
                    : "z-50 hidden my-4 text-base list-none bg-white divide-y divide-gray-100 rounded shadow dark:bg-gray-700 dark:divide-gray-600"
                }
                style={{
                  borderTopLeftRadius: "20px",
                  borderTopRightRadius: "20px",
                }}
                aria-labelledby="dropdownNotificationButton"
              >
                <div className="block px-4 py-2 font-medium text-center text-gray-700 rounded-t-lg bg-gray-50 dark:bg-gray-800 dark:text-white">
                  Notifications
                </div>
                <div className="notification-dropdown">
                  <Notification />
                </div>
              </div>
              <button
                type="button"
                className="flex text-sm bg-gray-800 rounded-full focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-600"
                aria-expanded="false"
                onClick={() => {
                  setUserOption(!userOption);
                  setNotification(false);
                }}
              >
                <span className="sr-only">Open user menu</span>
                <img
                  className="w-6 h-6 rounded-full"
                  src={currentUser.profileImage}
                  alt="user photo"
                />
              </button>
              <div
                className={
                  userOption === true
                    ? "absolute z-50 top-1/2 right-2 my-4 text-base list-none bg-white divide-y divide-gray-100 rounded shadow dark:bg-gray-700 dark:divide-gray-600"
                    : "z-50 hidden my-4 text-base list-none bg-white divide-y divide-gray-100 rounded shadow dark:bg-gray-700 dark:divide-gray-600"
                }
                id="dropdown-user"
              >
                <div className="px-4 py-3" role="none">
                  <p
                    className="text-sm text-gray-900 dark:text-white"
                    role="none"
                  >
                    {currentUser.name}
                  </p>
                  <p
                    className="text-sm font-medium text-gray-900 truncate dark:text-gray-300"
                    role="none"
                  >
                    {currentUser.email}
                  </p>
                </div>
                <ul className="py-1" role="none">
                  <li>
                    <Link
                      to={`/settings/${currentUser._id}`}
                      className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white"
                      role="menuitem"
                    >
                      Settings
                    </Link>
                  </li>
                  <li>
                    <a
                      className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white cursor-pointer"
                      role="menuitem"
                      onClick={handleLogout}
                    >
                      Sign out
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </nav>

      <aside
        id="logo-sidebar"
        className={
          sideBar === true
            ? "fixed top-0 left-0 z-40 w-64 h-screen pt-20 transition-transform bg-white border-r border-gray-200 sm:translate-x-0 dark:bg-gray-800 dark:border-gray-700"
            : "fixed top-0 left-0 z-40 w-64 h-screen pt-20 transition-transform -translate-x-full bg-white border-r border-gray-200 xlg:translate-x-0 dark:bg-gray-800 dark:border-gray-700"
        }
        aria-label="Sidebar"
      >
        <div className="h-full px-3 pb-4 overflow-y-auto bg-white dark:bg-gray-800">
          <ul className="space-y-2 font-medium">
            <li>
              <Link
                to="/"
                className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"
              >
                <svg
                  aria-hidden="true"
                  className="w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M2 10a8 8 0 018-8v8h8a8 8 0 11-16 0z"></path>
                  <path d="M12 2.252A8.014 8.014 0 0117.748 8H12V2.252z"></path>
                </svg>
                <span className="ml-3">Dashboard</span>
              </Link>
            </li>
            {role === "Manager" && (
              <>
                <li>
                  <Link
                    to="/manager/completed-requests"
                    className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"
                  >
                    <svg
                      fill="none"
                      stroke="currentColor"
                      strokeWidth={1.5}
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                      aria-hidden="true"
                      style={{ width: 24 }}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                    <span className="flex-1 ml-3 whitespace-nowrap">
                      Completed Requests
                    </span>
                  </Link>
                </li>
                <li>
                  <Link
                    to="/manager/users"
                    className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"
                  >
                    <svg
                      aria-hidden="true"
                      className="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    <span className="flex-1 ml-3 whitespace-nowrap">Users</span>
                  </Link>
                </li>
              </>
            )}

            {role === DISPATCHER_ROLE && (
              <li>
                <Link
                  to="/dispatcher/technicians"
                  className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"
                >
                  <svg
                    aria-hidden="true"
                    className="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                  <span className="flex-1 ml-3 whitespace-nowrap">
                    Technicians Lists
                  </span>
                </Link>
              </li>
            )}
            {role === MANAGER_ROLE && (
              <li>
                <Link
                  to="/technicians"
                  className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"
                >
                  <svg
                    aria-hidden="true"
                    className="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                  <span className="flex-1 ml-3 whitespace-nowrap">
                    Technicians Lists
                  </span>
                </Link>
              </li>
            )}
            {paymentMethodShow && (
              <li>
                <Link
                  to="/payment"
                  className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"
                >
                  <svg
                    className="w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 16 20"
                  >
                    <path d="M16 14V2a2 2 0 0 0-2-2H2a2 2 0 0 0-2 2v15a3 3 0 0 0 3 3h12a1 1 0 0 0 0-2h-1v-2a2 2 0 0 0 2-2ZM4 2h2v12H4V2Zm8 16H3a1 1 0 0 1 0-2h9v2Z"></path>
                  </svg>
                  <span className="flex-1 ml-3 whitespace-nowrap">
                    Payment Request
                  </span>
                </Link>
              </li>
            )}

            <li>
              <button
                type="button"
                className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"
                onClick={handleLogout}
              >
                <svg
                  aria-hidden="true"
                  className="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M3 3a1 1 0 00-1 1v12a1 1 0 102 0V4a1 1 0 00-1-1zm10.293 9.293a1 1 0 001.414 1.414l3-3a1 1 0 000-1.414l-3-3a1 1 0 10-1.414 1.414L14.586 9H7a1 1 0 100 2h7.586l-1.293 1.293z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                <span className="flex-1 ml-3 whitespace-nowrap">Sign out</span>
              </button>
            </li>
            <div className="absolute bottom-0 left-0 w-full px-8 py-4 bg-gray-900">
              <Link to="/" className="flex ml-2 md:mr-24">
                <img src={Logo} className="h-8 mr-3" alt=" Logo" />
                <span className="self-center text-xl font-semibold sm:text-2xl whitespace-nowrap dark:text-white">
                  <span className="text-logoRedColor">MJN</span>{" "}
                  <span className="text-logoTextSecoundColor">Facility</span>
                </span>
              </Link>
              <span className="text-xs flex text-gray-400 justify-center text-center">
                Version {process.env.REACT_APP_APP_VERSION}
              </span>
            </div>
          </ul>
        </div>
      </aside>

      {requestCallModal && (
        <RequestCallModal
          setIsopen={setRequestCallModal}
          isopen={requestCallModal}
        />
      )}
    </>
  );
};

export default Navbar;
