import io from "socket.io-client";

const userSocket = io(
  `${process.env.REACT_APP_SOCKET_API}/users-status-socket`,
  {
    autoConnect: false,
  }
);

const usersStatus = (data) => {
  userSocket.emit("usersStatus", data);
};

const receiveUsersStatus = (setUsersStatus) => {
  return userSocket.on("receiveUsersStatus", (data) => {
    setUsersStatus(data);
  });
};

export { usersStatus, receiveUsersStatus, userSocket };
