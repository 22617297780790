import React, { useEffect, useState } from "react";
import { getInvoicerRequest, searchInvoicer } from "../../API";
import { useDispatch, useSelector } from "react-redux";
import {
  setRequests,
  addRequest,
  updateRequest,
} from "../../redux/reducers/requestReducer";
import {
  resevedStatus,
  updateReceiveStatus,
} from "../../components/socket/StatusSocket";
import { logoutReducer } from "../../redux/reducers/authReducer";
import InvoicerRequestCallTable from "../../components/InvoicerRequestCallTable";

const Invoicer = () => {
  const [loading, setLoading] = useState(false);
  const [searchKey, setSearchKey] = useState("Select Filter");
  const [noDataFoundShow, setNoDataFoundShow] = useState(false);
  const [totals, setTotals] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const dispatch = useDispatch();

  const handleGetRequestData = () => {
    setLoading(true);
    getInvoicerRequest(currentPage, 10).then((res) => {
      if (res.success) {
        dispatch(setRequests(res.requestTech));
        setTotals({
          requestTechTotal: res.requestTechTotal,
        });
        setTotalPages(res.totalPages);
        setLoading(false);
      } else {
        setNoDataFoundShow(true);
        setLoading(false);

        if (res === "The token is invalid or expired") {
          dispatch(logoutReducer());
          setLoading(false);
        }
      }
    });
  };

  const handleSearch = (search) => {
    if (searchKey === "name" || searchKey === "workOrderNumber") {
      searchInvoicer(searchKey, search)
        .then((res) => {
          if (res.success) {
            dispatch(setRequests(res.requestCalls));
          } else {
            setNoDataFoundShow(true);
            if (res === "The token is invalid or expired") {
              dispatch(logoutReducer());
            }
          }
        })
        .catch((error) => {
          console.error("Error searching requested calls:", error);
          setNoDataFoundShow(true);
        });
    } else {
      // Handle other search keys or cases here
    }
  };

  useEffect(() => {
    handleGetRequestData();
    resevedStatus(dispatch, addRequest);
    updateReceiveStatus(dispatch, updateRequest);
  }, []);

  const tableHeader = [
    "#",
    "Name",
    "Location",
    "Work Order Number",
    "Time To Arrive",
    "Status",
    "Action",
  ];

  const { requests } = useSelector((state) => state.requests);
  const { sideBar } = useSelector((state) => state.setSideBar);

  return (
    <div className={`p-4 ${sideBar && "sm:ml-64"}`}>
      <div className="p-4 rounded-lg mt-14">
        <span className="text-2xl font-bold text-gray-900 dark:text-white mb-4">
          Invoicer Requested Calls
        </span>
        <InvoicerRequestCallTable
          tableHeader={tableHeader}
          tableData={requests}
          loading={loading}
          handleSearch={handleSearch}
          setSearchKey={setSearchKey}
          searchKey={searchKey}
          noDataFoundShow={noDataFoundShow}
          totals={totals}
          totalPages={totalPages}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </div>
    </div>
  );
};

export default Invoicer;
