import React, { useState } from "react";
import { Link } from "react-router-dom";
import TableLoading from "./Loading/TableLoading";
import { getTotalData, sendRequestToTeamLeader, exportData } from "../API";
import { toast } from "react-toastify";
import { sendUpdateStatus } from "./socket/StatusSocket";
import Search from "./Search";
import EmptyData from "../components/EmptyData";
import { sendNotification } from "./socket/notificationSocket";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import {
  ALL_JOBS,
  IN_PROGRESS,
  PENDING,
  renderPaginationButtons,
} from "../GlobalFunctions";
import DeleteModal from "./Modals/DeleteModal";

const RequestCallTable = ({
  tableHeader,
  tableData,
  loading,
  companies,
  teamLeaders,
  handleSearch,
  setSearchKey,
  searchKey,
  noDataFoundShow,
  handleFilter,
  totalPages,
  currentPage,
  setCurrentPage,
}) => {
  const { userId, currentUser } = useSelector((state) => state.auth);
  const [comapnyId, setComapnyId] = useState("");
  const [teamLeaderId, setTeamLeaderId] = useState("");
  const [totals, setTotals] = useState({});
  const [openDelete, setOpenDelete] = useState(false);
  const [deleteId, setDeleteId] = useState("");

  const handleSendRequest = (id, companieasId) => {
    let companyDataId = comapnyId || companieasId;

    sendRequestToTeamLeader(
      id,
      companyDataId,
      teamLeaderId,
      userId,
      teamLeaderId
    ).then((res) => {
      if (res.success) {
        sendUpdateStatus(res.request);
        const notificationData = {
          message: `You have new Job From ${res.request.name}`,
          userId: teamLeaderId,
          requestTechId: res.request._id,
          requestTechName: res.request.name,
        };
        sendNotification(notificationData);
        toast.success(`${res.request.name} Request Updated successfully.`);
      }
    });
  };

  const handleGetTotalData = () => {
    getTotalData().then((response) => {
      if (response.success) {
        setTotals(response);
      }
    });
  };

  const handleExportData = async () => {
    try {
      const response = await exportData();
      if (response.success) {
        const blob = new Blob([response.csvData], { type: "text/csv" });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "MJNRequestedCalls.csv";
        document.body.appendChild(a);
        a.click();
        a.remove();
      } else {
        console.error("Export failed:", response.statusText);
        toast.error("Export failed.");
      }
    } catch (error) {
      console.error("Error exporting data:", error);
      toast.error("An error occurred while exporting data.");
    }
  };

  useEffect(() => handleGetTotalData(), []);

  return (
    <>
      {loading ? (
        <TableLoading />
      ) : (
        <>
          {noDataFoundShow ? (
            <EmptyData />
          ) : (
            <div className="relative overflow-x-auto shadow-md sm:rounded-lg mt-4">
              <div className="request-call-topbar">
                <div className="grid-child -4 bg-white dark:bg-gray-900">
                  <Search
                    handleSearch={handleSearch}
                    setSearchKey={setSearchKey}
                    searchKey={searchKey}
                  />
                </div>
                <div className="topbar-info">
                  <button
                    className="grid-child bg-blue-100 text-blue-800 font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300"
                    style={{ padding: "10px" }}
                    onClick={() => handleFilter(ALL_JOBS)}
                  >
                    ({totals?.totalRequestCalls}) Opened jobs
                  </button>

                  <button
                    className="grid-child bg-yellow-100 text-yellow-800 font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-yellow-900 dark:text-yellow-300"
                    style={{ padding: "10px" }}
                    onClick={() => handleFilter(PENDING)}
                  >
                    ({totals?.totalPending}) Pending jobs
                  </button>
                  <button
                    className="grid-child bg-purple-100 text-purple-800 font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-purple-900 dark:text-purple-300"
                    style={{ padding: "10px" }}
                    onClick={() => handleFilter(IN_PROGRESS)}
                  >
                    ({totals?.totalToDispatcher}) In progress
                  </button>
                  <button
                    className="grid-child bg-green-100 text-green-800 font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-300"
                    onClick={() => handleExportData()}
                  >
                    Export Data
                  </button>
                </div>
              </div>

              <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                    {tableHeader?.map((item, index) => (
                      <th scope="col" className="px-6 py-3" key={index}>
                        <div className="flex items-center">
                          {item}
                          {item === "Name" ||
                            item === "Location" ||
                            (item === "Created At" && (
                              <button type="button">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="w-3 h-3 ml-1"
                                  aria-hidden="true"
                                  fill="currentColor"
                                  viewBox="0 0 320 512"
                                >
                                  <path d="M27.66 224h264.7c24.6 0 36.89-29.78 19.54-47.12l-132.3-136.8c-5.406-5.406-12.47-8.107-19.53-8.107c-7.055 0-14.09 2.701-19.45 8.107L8.119 176.9C-9.229 194.2 3.055 224 27.66 224zM292.3 288H27.66c-24.6 0-36.89 29.77-19.54 47.12l132.5 136.8C145.9 477.3 152.1 480 160 480c7.053 0 14.12-2.703 19.53-8.109l132.3-136.8C329.2 317.8 316.9 288 292.3 288z" />
                                </svg>
                              </button>
                            ))}
                        </div>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {tableData?.map((data, index) => {
                    const timeArivalCheck =
                      data.arrivalTime <= "00:10" &&
                      data.status === "Confirmed";

                    const rowNumber = (currentPage - 1) * 10 + index + 1;
                    return (
                      <>
                        <tr
                          className={`bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600 ${
                            timeArivalCheck && "timeArrivedDone"
                          }`}
                          key={data._id}
                        >
                          <th className="px-6 py-4 relative">
                            <div className="group absolute top-0 left-3">
                              {data?.emergencyHourlyRate && (
                                <svg
                                  className="emergency"
                                  height="30"
                                  width="30"
                                  version="1.1"
                                  id="Capa_1"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 310.806 310.806"
                                >
                                  <path
                                    d="M305.095,229.104L186.055,42.579c-6.713-10.52-18.172-16.801-30.652-16.801c-12.481,0-23.94,6.281-30.651,16.801
   L5.711,229.103c-7.145,11.197-7.619,25.39-1.233,37.042c6.386,11.647,18.604,18.883,31.886,18.883h238.079
   c13.282,0,25.5-7.235,31.888-18.886C312.714,254.493,312.24,240.301,305.095,229.104z M155.403,253.631
   c-10.947,0-19.82-8.874-19.82-19.82c0-10.947,8.874-19.821,19.82-19.821c10.947,0,19.82,8.874,19.82,19.821
   C175.223,244.757,166.349,253.631,155.403,253.631z M182.875,115.9l-9.762,65.727c-1.437,9.675-10.445,16.353-20.119,14.916
   c-7.816-1.161-13.676-7.289-14.881-14.692l-10.601-65.597c-2.468-15.273,7.912-29.655,23.185-32.123
   c15.273-2.468,29.655,7.912,32.123,23.185C183.284,110.192,183.268,113.161,182.875,115.9z"
                                  />
                                </svg>
                              )}
                              <span className="absolute top-8 scale-0 rounded bg-gray-500 p-2 text-xs text-white group-hover:scale-100">
                                This Job Is Emergency
                              </span>
                            </div>
                            {rowNumber}
                          </th>
                          <th
                            scope="row"
                            className="hover:underline px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                          >
                            <Link
                              to={`/manager/details/request-details/${data._id}`}
                            >
                              {data?.name}
                            </Link>
                          </th>
                          <td className="px-6 py-4">{data?.location}</td>
                          <td className="px-6 py-4">
                            {data?.workOrderNumber ? data.workOrderNumber : "-"}
                          </td>
                          {/* <td className="px-6 py-4">{localTime}</td> */}
                          <td className="px-6 py-4">
                            {data?.arrivalTime
                              ? data.arrivalTime.replace(":AM", "")
                              : "00:00:00"}
                          </td>
                          <td
                            className={`px-6 py-4 ${
                              (data?.status === "Pending" &&
                                "text-yellow-300") ||
                              (data?.status === "To TeamLead" &&
                                "text-teal-500") ||
                              (data?.status === "To Dispatcher" &&
                                "text-blue-500") ||
                              (data?.status === "In Progress" &&
                                "text-purple-500") ||
                              (data?.status === "Closed" && "text-green-500") ||
                              (data?.status === "Cancelled" &&
                                "text-red-500") ||
                              (data?.status === "Waiting To Confirm" &&
                                "text-yellow-300") ||
                              (data?.status === "Sending To Client" &&
                                "text-blue-600") ||
                              (data?.status === "Technician Checked In" &&
                                "text-green-500") ||
                              (data?.status === "Accountant" &&
                                "text-yellow-500") ||
                              (data?.status === "ETA Needed" &&
                                "text-red-500") ||
                              (data?.status === "Waiting For Approval" &&
                                "text-yellow-300") ||
                              (data?.status === "Confirmed" && "text-green-500")
                            }`}
                          >
                            {data?.status === "To TeamLead"
                              ? "Not assigined"
                              : data?.status}
                          </td>
                          <td className="px-6 py-4">
                            {data.company ? (
                              <>
                                <div className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                  {data?.company?.name}
                                </div>
                              </>
                            ) : (
                              <>
                                <select
                                  id="select-company"
                                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                  onChange={(e) => setComapnyId(e.target.value)}
                                >
                                  <option value="">Select a company</option>

                                  {companies?.map((d) => (
                                    <option value={d._id} key={d._id}>
                                      {d.name}
                                    </option>
                                  ))}
                                </select>
                              </>
                            )}
                          </td>
                          <td className="px-6 py-4">
                            <>
                              <div className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                {data?.manger?.name || currentUser?.name}
                              </div>
                            </>
                          </td>
                          <td className="px-6 py-4">
                            <select
                              id="select-company"
                              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                              onChange={(e) => setTeamLeaderId(e.target.value)}
                            >
                              {!data?.teamLead?.name && (
                                <option value="">Select a Team Leader</option>
                              )}

                              {teamLeaders?.map((d) => (
                                <option
                                  value={d._id}
                                  key={d._id}
                                  selected={data?.teamLead?.name === d.name}
                                >
                                  {d.name}
                                </option>
                              ))}
                            </select>
                          </td>
                          <td className="flex px-6 py-4">
                            <button
                              className={`${"mx-2 bg-blue-500 hover:bg-blue-700"} text-white font-bold py-2 px-4 rounded  focus:shadow-outline`}
                              type="button"
                              onClick={() =>
                                handleSendRequest(
                                  data._id,
                                  data?.manger?._id,
                                  data?.company?._id
                                )
                              }
                            >
                              Send Request
                            </button>
                            <button
                              className={`${"mx-2 bg-red-500 hover:bg-red-700"} text-white font-bold py-2 px-4 rounded  focus:shadow-outline`}
                              type="button"
                              onClick={() => {
                                setDeleteId(data._id);
                                setOpenDelete(true);
                              }}
                            >
                              Delete Request
                            </button>
                            <input type="hidden" value={data._id} />
                          </td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </table>
              <div className="flex justify-center items-center">
                {renderPaginationButtons(
                  currentPage,
                  totalPages,
                  setCurrentPage
                )}
              </div>
            </div>
          )}
        </>
      )}

      {openDelete && (
        <DeleteModal
          isOpen={openDelete}
          setIsopen={setOpenDelete}
          data={deleteId}
        />
      )}
    </>
  );
};

export default RequestCallTable;
