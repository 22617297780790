import React, { useState } from "react";
import { Link } from "react-router-dom";
import TableLoading from "../components/Loading/TableLoading";
import AddTechModal from "./Modals/AddTechModal";
import Search from "./Search";
import EmptyData from "./EmptyData";
import {
  ALL_JOBS,
  IN_PROGRESS,
  renderPaginationButtons,
} from "../GlobalFunctions";

const DispatcherRequestCallTable = ({
  tableHeader,
  tableData,
  loading,
  setSearchKey,
  searchKey,
  handleSearch,
  noDataFoundShow,
  totals,
  handleFilter,
  totalPages,
  currentPage,
  setCurrentPage,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [requestId, setRequestId] = useState("");

  return (
    <>
      {loading ? (
        <TableLoading />
      ) : (
        <>
          {noDataFoundShow ? (
            <EmptyData />
          ) : (
            <div className="relative overflow-x-auto shadow-md sm:rounded-lg mt-4">
              <div className="request-call-topbar">
                <div className="grid-child bg-white dark:bg-gray-900">
                  <Search
                    handleSearch={handleSearch}
                    setSearchKey={setSearchKey}
                    searchKey={searchKey}
                  />
                </div>
                <div className="topbar-info">
                  <button
                    className="grid-child bg-blue-100 text-blue-800 font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300"
                    style={{ padding: "10px" }}
                    onClick={() => handleFilter(ALL_JOBS)}
                  >
                    ({totals?.requestTechTotal}) Opened jobs
                  </button>

                  <button
                    className="bg-green-100 text-green-800 font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-300"
                    style={{ padding: "10px" }}
                    onClick={() => handleFilter(IN_PROGRESS)}
                  >
                    ({totals?.requestTechPending}) In progress
                  </button>
                </div>
              </div>
              <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                    {tableHeader?.map((item, index) => (
                      <th scope="col" className="px-6 py-3" key={index}>
                        <div className="flex items-center">
                          {item}
                          {item === "Name" ||
                            item === "Location" ||
                            (item === "Created At" && (
                              <button type="button">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="w-3 h-3 ml-1"
                                  aria-hidden="true"
                                  fill="currentColor"
                                  viewBox="0 0 320 512"
                                >
                                  <path d="M27.66 224h264.7c24.6 0 36.89-29.78 19.54-47.12l-132.3-136.8c-5.406-5.406-12.47-8.107-19.53-8.107c-7.055 0-14.09 2.701-19.45 8.107L8.119 176.9C-9.229 194.2 3.055 224 27.66 224zM292.3 288H27.66c-24.6 0-36.89 29.77-19.54 47.12l132.5 136.8C145.9 477.3 152.1 480 160 480c7.053 0 14.12-2.703 19.53-8.109l132.3-136.8C329.2 317.8 316.9 288 292.3 288z" />
                                </svg>
                              </button>
                            ))}
                        </div>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {tableData?.map((data, index) => {
                    /*
                    const date = new Date(data?.createdAt);
                    const options = {
                      hour12: true,
                      year: "numeric",
                      month: "2-digit",
                      day: "2-digit",
                      minute: "2-digit",
                      hour: "numeric",
                    };
                    const localTime = date.toLocaleTimeString("en-US", options);
                    */
                    const timeArivalCheck =
                      data.arrivalTime <= "00:10" &&
                      data.status === "Confirmed";

                    const rowNumber = (currentPage - 1) * 10 + index + 1;
                    return (
                      <tr
                        className={`bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600 ${
                          timeArivalCheck && "timeArrivedDone"
                        }`}
                        key={data._id}
                      >
                        <th className="px-6 py-4 relative">
                          <div className="group absolute top-0 left-3">
                            {data?.emergencyHourlyRate && (
                              <svg
                                className="emergency"
                                height="30"
                                width="30"
                                version="1.1"
                                id="Capa_1"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 310.806 310.806"
                              >
                                <path
                                  d="M305.095,229.104L186.055,42.579c-6.713-10.52-18.172-16.801-30.652-16.801c-12.481,0-23.94,6.281-30.651,16.801
   L5.711,229.103c-7.145,11.197-7.619,25.39-1.233,37.042c6.386,11.647,18.604,18.883,31.886,18.883h238.079
   c13.282,0,25.5-7.235,31.888-18.886C312.714,254.493,312.24,240.301,305.095,229.104z M155.403,253.631
   c-10.947,0-19.82-8.874-19.82-19.82c0-10.947,8.874-19.821,19.82-19.821c10.947,0,19.82,8.874,19.82,19.821
   C175.223,244.757,166.349,253.631,155.403,253.631z M182.875,115.9l-9.762,65.727c-1.437,9.675-10.445,16.353-20.119,14.916
   c-7.816-1.161-13.676-7.289-14.881-14.692l-10.601-65.597c-2.468-15.273,7.912-29.655,23.185-32.123
   c15.273-2.468,29.655,7.912,32.123,23.185C183.284,110.192,183.268,113.161,182.875,115.9z"
                                />
                              </svg>
                            )}
                            <span className="absolute top-8 scale-0 rounded bg-gray-500 p-2 text-xs text-white group-hover:scale-100">
                              This Job Is Emergency
                            </span>
                          </div>
                          {rowNumber}
                        </th>{" "}
                        <th
                          scope="row"
                          className="hover:underline px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                        >
                          <Link
                            to={`/dispatcher/details/request-details/${data._id}`}
                          >
                            {data?.name}
                          </Link>
                        </th>
                        <td className="px-6 py-4">{data?.location}</td>
                        <td className="px-6 py-4">
                          {data?.workOrderNumber ? data.workOrderNumber : "-"}
                        </td>
                        {/* <td className="px-6 py-4">{localTime}</td> */}
                        <td className="px-6 py-4">
                          {data?.arrivalTime ? data.arrivalTime : "00:00:00"}
                        </td>
                        <td
                          className={`px-6 py-4 ${
                            (data?.status === "Pending" && "text-yellow-300") ||
                            (data?.status === "To TeamLead" &&
                              "text-teal-500") ||
                            (data?.status === "To Dispatcher" &&
                              "text-blue-500") ||
                            (data?.status === "In Progress" &&
                              "text-purple-500") ||
                            (data?.status === "Closed" && "text-green-500") ||
                            (data?.status === "Cancelled" && "text-red-500") ||
                            (data?.status === "Waiting To Confirm" &&
                              "text-yellow-300") ||
                            (data?.status === "Sending To Client" &&
                              "text-blue-600") ||
                            (data?.status === "Technician Checked In" &&
                              "text-green-500") ||
                            (data?.status === "Accountant" &&
                              "text-yellow-500") ||
                            (data?.status === "ETA Needed" && "text-red-500") ||
                            (data?.status === "Waiting For Approval" &&
                              "text-yellow-300") ||
                            (data?.status === "Confirmed" && "text-green-500")
                          }`}
                        >
                          {data?.status === "To TeamLead"
                            ? "Not assigined"
                            : data?.status}
                        </td>
                        <td className="px-6 py-4">
                          <button
                            className={`${
                              data?.tech?.name
                                ? "bg-blue-300 "
                                : "bg-blue-500 hover:bg-blue-700"
                            } text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline disable`}
                            type="button"
                            disabled={data?.tech?.name ? true : false}
                            onClick={() => {
                              setRequestId(data._id);
                              setIsOpen(true);
                            }}
                          >
                            Add Technician
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <div className="flex justify-center items-center">
                {renderPaginationButtons(
                  currentPage,
                  totalPages,
                  setCurrentPage
                )}
              </div>
            </div>
          )}
        </>
      )}

      {isOpen && (
        <AddTechModal
          isOpen={isOpen}
          setIsopen={setIsOpen}
          RequestTechSchemaId={requestId}
        />
      )}
    </>
  );
};

export default DispatcherRequestCallTable;
