import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setLocaleString } from "../../../GlobalFunctions";
import { deleteCostAPi } from "../../../API";
import { toast } from "react-toastify";
import { deleteCost } from "../../../redux/reducers/costReducer";

const Costs = () => {
  const { costs } = useSelector((state) => state.cost);
  const [openAccordion, setOpenAccordion] = useState("");

  const dispatch = useDispatch();

  const handleAccordionToggle = (costId) => {
    setOpenAccordion((prevId) => (prevId === costId ? "" : costId));
  };

  const handleRemoveClick = (costId) => {
    deleteCostAPi(costId).then(() => {
      dispatch(deleteCost(costId));
      toast.success(`Cost delete successfully`);
    });
  };

  return (
    <>
      {costs?.map((cost) => (
        <dl
          className="max-w-xlg text-gray-900 divide-y divide-gray-200 dark:text-white dark:divide-gray-700 mt-5 relative"
          key={cost._id}
        >
          <div id={`accordion-collapse-${cost._id}`} data-accordion="collapse">
            <h2 id={`accordion-collapse-heading-${cost._id}`}>
              <button
                type="button"
                className="flex items-center justify-between w-full p-5 font-medium text-left border border-b-0 border-gray-200 rounded-t-xl focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 hover:bg-gray-100 dark:hover:bg-gray-800 bg-gray-100 dark:bg-gray-800 text-gray-900 dark:text-white"
                data-accordion-target={`#accordion-collapse-body-${cost._id}`}
                aria-expanded={openAccordion === cost._id}
                aria-controls={`accordion-collapse-body-${cost._id}`}
                onClick={() => handleAccordionToggle(cost._id)}
              >
                <span>
                  {cost.name} : {setLocaleString(cost.cost)}
                </span>
                <svg
                  data-accordion-icon
                  className={`w-6 h-6 shrink-0 ${
                    openAccordion === cost._id ? "" : "rotate-180"
                  }`}
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </button>
            </h2>
            <div
              id={`accordion-collapse-body-${cost._id}`}
              className={openAccordion === cost._id ? "" : "hidden"}
              aria-labelledby={`accordion-collapse-heading-${cost._id}`}
            >
              <div className="p-5 border border-b-0 border-gray-200 dark:border-gray-700 dark:bg-gray-900">
                <p className="mb-2 text-gray-500 dark:text-gray-400">
                  {cost.information}
                </p>
                <button
                  type="button"
                  className="bg-red-500 text-white hover:bg-red-600 px-4 py-2 rounded"
                  onClick={() => handleRemoveClick(cost._id)}
                >
                  Remove
                </button>
              </div>
            </div>
          </div>
        </dl>
      ))}
    </>
  );
};

export default Costs;
