import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { DISPATCHER_ROLE } from "../../../GlobalFunctions";
import AddPaymentRequest from "../../../components/Modals/AddPaymentRequest";
import { toast } from "react-toastify";

const PaymentRequest = ({ data, requestData, requestCallId, NTEData }) => {
  const { role } = useSelector((state) => state.auth);
  const { id } = useParams();
  const [paymentRequest, setPaymentReqModal] = useState(false);
  console.log("====================================");
  console.log(NTEData);
  console.log("====================================");

  const handleOnClick = () => {
    if (NTEData.includes("$NaN")) {
      toast.warn("Please add NTE price on cost tab");
    } else {
      setPaymentReqModal(true);
    }
  };
  return (
    <>
      <div className="flex justify-between items-center">
        <div>
          <span className="text-2xl font-semibold text-gray-900 dark:text-white">
            Payment Request
          </span>
        </div>

        <>
          {role === DISPATCHER_ROLE ? (
            <>
              {
                <div className="flex">
                  <button
                    className="mx-2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    onClick={handleOnClick}
                    disabled={
                      data.requestCallId === requestCallId &&
                      data.technicianName
                        ? true
                        : false
                    }
                  >
                    {data.requestCallId === requestCallId && data.technicianName
                      ? "Added"
                      : "Add Payment Request"}
                  </button>
                </div>
              }
            </>
          ) : (
            ""
          )}
        </>
      </div>

      <dl className="grid grid-cols-2 gap-4 text-gray-900 dark:text-white mt-5">
        <div className="flex flex-col pb-3 pt-4">
          <dt className="mb-1 text-gray-500 md:text-lg dark:text-gray-400">
            Technician name
          </dt>
          <dd className="text-lg font-semibold">
            {data.requestCallId === requestCallId && data?.technicianName}
          </dd>
        </div>
        <div className="flex flex-col py-3">
          <dt className="mb-1 text-gray-500 md:text-lg dark:text-gray-400">
            Phone number
          </dt>
          <dd className="text-lg font-semibold">
            {data.requestCallId === requestCallId && data?.phoneNumber}
          </dd>
        </div>
        <div className="flex flex-col py-3">
          <dt className="mb-1 text-gray-500 md:text-lg dark:text-gray-400">
            Payment Method
          </dt>
          <dd className="text-lg font-semibold">
            {data.requestCallId === requestCallId && data?.paymentMethod}
          </dd>
        </div>

        <div className="flex flex-col py-3">
          <dt className="mb-1 text-gray-500 md:text-lg dark:text-gray-400">
            Amount
          </dt>
          <dd className="text-lg font-semibold">
            {data.requestCallId === requestCallId && data?.amount}
          </dd>
        </div>
        <div className="flex flex-col py-3">
          <dt className="mb-1 text-gray-500 md:text-lg dark:text-gray-400">
            Tax ID
          </dt>
          <dd className="text-lg font-semibold">
            {data.requestCallId === requestCallId && data?.taxId}
          </dd>
        </div>
        <div className="flex flex-col py-3">
          <dt className="mb-1 text-gray-500 md:text-lg dark:text-gray-400">
            Status
          </dt>
          <dd className="text-lg font-semibold">
            {data.requestCallId === requestCallId && data?.status}
          </dd>
        </div>
      </dl>

      {paymentRequest && (
        <AddPaymentRequest
          isOpen={paymentRequest}
          setIsopen={setPaymentReqModal}
          RequestTechSchemaId={id}
          teamLeaderId={requestData?.teamLead}
          requestData={requestData}
        />
      )}
    </>
  );
};

export default PaymentRequest;
