import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Editor } from "@tinymce/tinymce-react";
import { Global, css } from "@emotion/react";
import { useParams } from "react-router-dom";
import { addProposalNTE } from "../../../API";
import { toast } from "react-toastify";
import { updateRequestData } from "../../../redux/reducers/requestReducer";
import { DISPATCHER_ROLE, MANAGER_ROLE } from "../../../GlobalFunctions";

const Proposal = ({ data }) => {
  const { role } = useSelector((state) => state.auth);
  const [proposal, setProposal] = useState("");
  const [defultValue, setDefultValue] = useState("");
  const { id } = useParams();
  const dispatch = useDispatch();

  const onChange = (content) => {
    setProposal(content);
    addProposalNTE(id, content).then((result) => {
      if (result.data.success) {
        dispatch(updateRequestData(result.data.requestData));
      } else {
        toast.error("Something went wrong!");
      }
    });
  };

  const onSubmit = () => {
    addProposalNTE(id, proposal).then((result) => {
      if (result.data.success) {
        dispatch(updateRequestData(result.data.requestData));
        toast.success("Proposal created successfully");
      } else {
        toast.error("Something went wrong!");
      }
    });
  };

  const inutialValue = () => {
    if (data.issue) {
      setDefultValue(data.issue.proposerNTE);
    } else {
      setDefultValue(`
            <h1>WO#</h1>
            <h2>SOW:</h2>
            <h2><br />Tech Reported:</h2>
            <h2><br />Required to:<br />- Full inspection of the work area.<br />- Step 1<br />- Step 2<br />- Step 3<br />-Cleaning of work area</h2>
            <h2>Incurred</h2>
            <h2>&nbsp;</h2>
            <h2>Trip $</h2>
            <h2><br />Assessment $</h2>
            <h2>Repair</h2>
            <h2><br />Labor Hours Tech $<br />Labor Hours Helper $</h2>
            <h2><br /><strong>Parts and Materials</strong></h2>
            <h2><br />Equipment&nbsp;</h2>
            <h1><br />Total $</h1> `);
    }
  };

  useEffect(() => inutialValue(), []);
  return (
    <>
      <div className=" justify-center align-center">
        <div>
          <Global
            styles={css`
              .tox-notifications-container {
                display: none !important;
              }
            `}
          />
          <Editor
            apiKey="ntunkx7goqcqoshf2m6jwbfmzyr8a2jlxciwjqs9a7fs6tqc"
            initialValue={defultValue}
            init={{
              branding: false,
              height: 400,
              menubar: true,
              plugins:
                "print preview paste searchreplace autolink directionality visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern",
              toolbar:
                "formatselect | bold italic underline strikethrough | forecolor backcolor blockquote | link image media | alignleft aligncenter alignright alignjustify | numlist bullist outdent indent | removeformat",
              image_advtab: true,
            }}
            onEditorChange={onChange}
            disabled={role !== DISPATCHER_ROLE && role !== MANAGER_ROLE && true}
          />
        </div>

        <div className="mt-10">
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            onClick={onSubmit}
          >
            Submit
          </button>
        </div>
      </div>
    </>
  );
};

export default Proposal;
