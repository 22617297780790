import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { sendChat, reseveChat } from "../../../components/socket/chatSocket";
import { useSelector, useDispatch } from "react-redux";
import { setChat } from "../../../redux/reducers/chatReducer";
import { getChats } from "../../../API";

const Chat = () => {
  const { id } = useParams();
  const [message, setMessage] = useState("");
  const [messageSent, setMessageSent] = useState(false);
  const [mentionInput, setMentionInput] = useState("");
  const [mentionOptions, setMentionOptions] = useState([]);
  const [selectedMention, setSelectedMention] = useState(null);
  const chatContainerRef = useRef(null);
  const { userId, role } = useSelector((state) => state.auth);
  const { chat } = useSelector((state) => state.chat);
  const { requestData } = useSelector((state) => state.requests);

  const mentionsUsers = [
    { id: 1, name: "Manager" },
    { id: 2, name: "Team Leader" },
    { id: 3, name: "Dispatcher" },
  ];

  const dispatch = useDispatch();

  const handleSendMessage = (e) => {
    e.preventDefault();
    const data = {
      message: message,
      requestTechId: requestData._id,
      sender: userId,
    };
    sendChat(data);
    setMessageSent(true);
  };

  const handleEnterPress = (e) => {
    if (e.key === "Enter") {
      handleSendMessage(e);
    }
  };

  const handleGetChats = () => {
    getChats(requestData._id).then((res) => {
      dispatch(setChat(res.chats));
    });
  };

  useEffect(() => {
    handleGetChats();
    reseveChat(dispatch, setChat);
  }, []);

  useEffect(() => {
    if (messageSent) {
      setMessage("");
      setMessageSent(false);
    }
  }, [messageSent]);

  useEffect(() => {
    const mentionRegex = /@(\w+)/g;
    const mentions = message.match(mentionRegex);
    if (mentions) {
      mentions.forEach((mention) => {
        const mentionedName = mention.substring(1).toLowerCase();
        const matchedUsers = mentionsUsers.filter((user) =>
          user.name.toLowerCase().includes(mentionedName)
        );
        setMentionOptions(matchedUsers);
      });
    } else {
      setMentionOptions([]);
    }
  }, [message]);

  useEffect(() => {
    if (selectedMention) {
      const mentionText = `@${selectedMention.name}`;
      const composedMessage = message.replace(new RegExp(`@\\w+`), mentionText);
      setMessage(composedMessage);
      setSelectedMention(null); // Clear the selected mention
    }
  }, [selectedMention]);

  useEffect(() => {
    // Scroll to the bottom of the chat container
    chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
  }, [chat]);

  const MentionOptions = ({ users }) => {
    const handleMentionSelect = (user) => {
      setSelectedMention(user);
      setMentionInput(""); // Clear the mention input
    };
    return (
      <ul className="mention-options text-gray-900 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-800 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
        {users.map((user) => (
          <li key={user.id} onClick={() => handleMentionSelect(user)}>
            {user.name}
          </li>
        ))}
      </ul>
    );
  };
  return (
    <>
      <div
        className="flex-1 p:2 sm:p-6 justify-between flex flex-col"
        style={{ height: "70vh" }}
      >
        <div
          ref={chatContainerRef}
          id="messages"
          className="flex flex-col space-y-4 p-3 overflow-y-auto scrollbar-thumb-blue scrollbar-thumb-rounded scrollbar-track-blue-lighter scrollbar-w-2 scrolling-touch"
        >
          {chat?.map((item) => {
            const originalDate = new Date(item?.createdAt);
            let formattedDate;

            if (!isNaN(originalDate.getTime())) {
              const year = originalDate?.getFullYear();
              const month = String(originalDate?.getMonth() + 1)?.padStart(
                2,
                "0"
              );
              const day = String(originalDate?.getDate())?.padStart(2, "0");
              const hours = String(originalDate?.getUTCHours())?.padStart(
                2,
                "0"
              );
              const minutes = String(originalDate?.getUTCMinutes())?.padStart(
                2,
                "0"
              );
              const seconds = String(originalDate?.getUTCSeconds())?.padStart(
                2,
                "0"
              );

              formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
            }

            return (
              <div className="chat-message">
                <div className="flex items-end">
                  <div className="flex flex-col space-y-2 text-xs max-w-xs mx-2 order-2 items-start">
                    <div className="flex flex-col">
                      <span className="text-white py-2">
                        {item.sender.name}
                      </span>
                      <span className="px-4 py-2 rounded-lg inline-block rounded-bl-none bg-gray-300 text-gray-600">
                        {item.message}
                      </span>
                      <span className="text-gray-400 text-xs mt-1">
                        {formattedDate}
                      </span>{" "}
                    </div>
                  </div>
                  <img
                    src={item.sender.profileImage}
                    alt={item.sender.name}
                    className="w-6 h-6 rounded-full order-1"
                  />
                </div>
              </div>
            );
          })}
        </div>
        <div className="border-t-2 border-gray-200 pt-4 mb-2 sm:mb-0">
          <div className="relative">
            <div className="mention-container">
              {mentionInput && <MentionOptions users={mentionOptions} />}
              <textarea
                id="chat"
                rows="1"
                className="block p-2.5 w-full text-sm text-gray-900 bg-white rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-800 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Your message..."
                value={message}
                onChange={(e) => {
                  const inputText = e.target.value;
                  const lastWord = inputText.split(" ").pop();
                  if (lastWord.startsWith("@")) {
                    const mentionedName = lastWord.substring(1).toLowerCase();
                    const matchedUsers = mentionsUsers.filter((user) =>
                      user.name.toLowerCase().startsWith(mentionedName)
                    );
                    setMentionOptions(matchedUsers);
                    setMentionInput(mentionedName);
                  } else {
                    setMentionOptions([]);
                    setMentionInput("");
                  }
                  setMessage(inputText); // Update the message with the input value as normal
                }}
                onKeyDown={handleEnterPress}
              ></textarea>
            </div>
            <div className="absolute right-0 items-center inset-y-0 hidden sm:flex">
              <button
                type="submit"
                className="inline-flex justify-center p-2 rounded-lg text-blue-600 cursor-pointer hover:bg-blue-100 dark:text-blue-500 dark:hover:text-white dark:hover:bg-gray-600"
                onClick={handleSendMessage}
              >
                <svg
                  aria-hidden="true"
                  className="w-6 h-6 rotate-90"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z"></path>
                </svg>
                <span className="sr-only">Send message</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Chat;
