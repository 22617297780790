import React, { useEffect, useState } from "react";
import Chart from "chart.js/auto";
import { CategoryScale } from "chart.js";
import LineChart from "../../components/Chart/LineChart";
import { getCommission } from "../../API";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

const Commission = () => {
  Chart.register(CategoryScale);

  const [commission, setCommission] = useState([]);
  const [salary, setSalary] = useState("");
  const { sideBar } = useSelector((state) => state.setSideBar);


  useEffect(() => {
    getCommission().then((res) => {
      if (res.success) {
        if (res.data === null) {
          toast.error("You don't have commission");
        } else {
          setCommission(res.data);
          setSalary(
            parseFloat(res.data.salary) + parseFloat(res.data.totalCommotion)
          );
        }
      }
    });
  }, []);

  const chartData = {
    labels: [
      "Total Job",
      "Total Job Loss",
      "Total Job Profit",
      "Total Commission",
    ],
    datasets: [
      {
        data: [
          commission.totalJob,
          commission.totalJobLoss,
          commission.totalJobProfit,
          commission.totalCommotion,
        ],
        backgroundColor: "rgba(75, 192, 192, 0.6)",
        borderColor: "rgba(75, 192, 192, 1)",
      },
    ],
  };

  return (
    <>
      <div className={`p-4 ${sideBar && "sm:ml-64"}`}>
        <div className="p-4 rounded-lg mt-14">
          <span className="text-2xl font-bold text-gray-900 dark:text-white mb-4">
            Commissions
          </span>
          <LineChart chartData={chartData} salary={salary} />
        </div>
      </div>
    </>
  );
};

export default Commission;
