import React from "react";
import ResetPassword from "./sections/ResetPassword";
import PersonalInformation from "./sections/PersonalInformation";
import { useSelector } from "react-redux";

const Settings = () => {
  const { sideBar } = useSelector((state) => state.setSideBar);

  return (
    <>
      <div className={`p-4 ${sideBar && "sm:ml-64"}`}>
        <div className="p-4 rounded-lg mt-14">
          <span className="text-2xl font-bold text-gray-900 dark:text-white mb-4">
            Settings
          </span>
          <PersonalInformation />
          <hr className="h-px my-8 bg-gray-200 border-0 dark:bg-gray-700" />
          <ResetPassword />
        </div>
      </div>
    </>
  );
};

export default Settings;
