export function utf8_to_b64(str) {
  return window.btoa(encodeURIComponent(escape(str)));
}

export function setLocaleString(price) {
  return price?.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  });
}

export function setLocaleDateString(date) {
  // Y-m-d H:i:s Date format
  return date?.toLocaleString("en-US", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  });
}

export function renderPaginationButtons(currentPage, totalPages, setCurrentPage) {
  const range = 5; // Number of pages to show in a set
  const buttons = [];

  let startPage = Math.max(currentPage - Math.floor(range / 2), 1);
  let endPage = Math.min(startPage + range - 1, totalPages);
  if (startPage > 1) {
    buttons.push(
      <button
        key="previous"
        style={{
          margin: "10px",
          padding: "5px 10px",
          background: "#ffffff",
          color: "#1F2937",
        }}
        onClick={() => setCurrentPage(currentPage - 1)}
      >
        {"< Previous"}
      </button>
    );
  }

  for (let i = startPage; i <= endPage; i++) {
    buttons.push(
      <button
        key={i}
        style={{
          margin: "10px",
          padding: "5px 10px",
          background: currentPage === i ? "#1F2937" : "#ffffff",
          color: currentPage === i ? "#ffffff" : "#1F2937",
        }}
        onClick={() => setCurrentPage(i)}
      >
        {i}
      </button>
    );
  }

  if (endPage < totalPages) {
    buttons.push(
      <button
        key="next"
        style={{
          margin: "10px",
          padding: "5px 10px",
          background: "#ffffff",
          color: "#1F2937",
        }}
        onClick={() => setCurrentPage(currentPage + 1)}
      >
        {">"}
      </button>
    );
  }

  return buttons;
};

export const ACCOUNTANT_ROLE = "Accountant";
export const TEAMLEADER_ROLE = "TeamLead";
export const DISPATCHER_ROLE = "Dispatchers";
export const MANAGER_ROLE = "Manager";
export const INVOICER_ROLE = "Invoicer";
export const ADMIN_ROLE = "Admin";
export const ALL_JOBS = "AllJob";
export const PENDING = "Pending";
export const IN_PROGRESS = "InProgress";
