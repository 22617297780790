import { createSlice } from "@reduxjs/toolkit";

export const sideBarReducer = createSlice({
  name: "sideBar",
  initialState: {
    sideBar: true,
  },
  reducers: {
    setSideBarOpen: (state, action) => {
      state.sideBar = action.payload;
    },
  },
});

export const { setSideBarOpen } = sideBarReducer.actions;

export default sideBarReducer.reducer;
