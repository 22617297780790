import { createSlice } from "@reduxjs/toolkit";

export const requests = createSlice({
  name: "requests",
  initialState: {
    requests: [],
    requestData: {},
  },
  reducers: {
    setRequests: (state, action) => {
      state.requests = action.payload;
    },

    updateRequest: (state, action) => {
      state.requests = state.requests.map((request) => {
        if (request._id === action.payload._id) {
          return action.payload;
        } else {
          return request;
        }
      });
    },

    addRequest: (state, action) => {
      state.requests = [...state.requests, action.payload].reverse();
    },

    deleteRequest: (state, action) => {
      state.requests = state.requests.filter(
        (request) => request._id !== action.payload
      );
    },

    setRequestData: (state, action) => {
      state.requestData = action.payload;
    },

    updateRequestData: (state, action) => {
      state.requestData = {
        ...state.requestData,
        ...action.payload,
      };
    },
  },
});

export const {
  setRequests,
  updateRequest,
  addRequest,
  deleteRequest,
  setRequestData,
  updateRequestData,
} = requests.actions;

export default requests.reducer;
