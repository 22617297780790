import React, { useEffect, useState } from "react";
import {
  getAllRequestData,
  getCompanies,
  getTeamLeaders,
  getAllManagers,
  searchRequestedCalls,
} from "../../API";
import { useDispatch, useSelector } from "react-redux";
import {
  setRequests,
  addRequest,
  updateRequest,
} from "../../redux/reducers/requestReducer";
import {
  resevedStatus,
  updateReceiveStatus,
} from "../../components/socket/StatusSocket";
import { resevedTimer } from "../../components/socket/timerSocket";
import { logoutReducer } from "../../redux/reducers/authReducer";
import AdminRequestCallTable from "../../components/AdminRequestCallTable";
import { ALL_JOBS, IN_PROGRESS, PENDING } from "../../GlobalFunctions";
import { current } from "@reduxjs/toolkit";

const Admin = () => {
  const [loading, setLoading] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [managers, setManagers] = useState([]);
  const [teamLeaders, setTeamLeaders] = useState([]);
  const [searchKey, setSearchKey] = useState("Select Filter");
  const [noDataFoundShow, setNoDataFoundShow] = useState(false);
  const { sideBar } = useSelector((state) => state.setSideBar);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const dispatch = useDispatch();

  const handleGetRequestData = () => {
    setLoading(true);
    getAllRequestData(currentPage, 10).then((res) => {
      if (res.success) {
        dispatch(setRequests(res.requestTech));
        setTotalPages(res.totalPages);
      } else {
        setNoDataFoundShow(true);
        if (res === "The token is invalid or expired") {
          dispatch(logoutReducer());
        }
      }
    });

    getAllManagers().then((res) => {
      if (res.success) {
        setManagers(res.users);
      }
    });

    getCompanies().then((res) => {
      if (res.companies) {
        setCompanies(res.companies);
      }
    });

    getTeamLeaders().then((res) => {
      if (res.success) {
        setTeamLeaders(res.teamLeader);
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    handleGetRequestData();
    resevedStatus(dispatch, addRequest);
    updateReceiveStatus(dispatch, updateRequest);
    resevedTimer(dispatch, updateRequest);
  }, []);

  const { requests } = useSelector((state) => state.requests);
  const tableHeader = [
    "#",
    "Name",
    "Location",
    "Work Order Number",
    "Time To Arrive",
    "Status",
    "Select Company",
    "Select Manager",
    "Select Team Leader",
    "Action",
  ];

  const handleFilter = (filter) => {
    if (filter === ALL_JOBS) {
      handleGetRequestData();
    } else if (filter === PENDING) {
      setLoading(true);
      getAllRequestData(currentPage, 10).then((res) => {
        if (res.success) {
          const pendingRequests = res.requestTech.filter(
            (request) => request.status === "Pending"
          );
          dispatch(setRequests(pendingRequests));
          setTotalPages(res.totalPages);
          setLoading(false);
        } else {
          setNoDataFoundShow(true);
          if (res === "The token is invalid or expired") {
            dispatch(logoutReducer());
          }
        }
      });
    } else if (filter === IN_PROGRESS) {
      setLoading(true);
      getAllRequestData(currentPage, 10).then((res) => {
        if (res.success) {
          const inProgressRequests = res.requestTech.filter(
            (request) => request.status === "To Dispatcher"
          );
          dispatch(setRequests(inProgressRequests));
          setTotalPages(res.totalPages);
          setLoading(false);
        } else {
          setNoDataFoundShow(true);
          if (res === "The token is invalid or expired") {
            dispatch(logoutReducer());
          }
        }
      });
    }
  };

  const handleSearch = (search) => {
    if (searchKey === "name" || searchKey === "workOrderNumber") {
      searchRequestedCalls(searchKey, search)
        .then((res) => {
          if (res.success) {
            dispatch(setRequests(res.requestCalls));
          } else {
            setNoDataFoundShow(true);
            if (res === "The token is invalid or expired") {
              dispatch(logoutReducer());
            }
          }
        })
        .catch((error) => {
          console.error("Error searching requested calls:", error);
          setNoDataFoundShow(true);
        });
    } else {
      // Handle other search keys or cases here
    }
  };

  return (
    <>
      <div className={`p-4 ${sideBar && "sm:ml-64"}`}>
        <div className="p-4 rounded-lg mt-14">
          <span className="text-2xl font-bold text-gray-900 dark:text-white mb-4">
            Admin Requested Calls
          </span>
          <AdminRequestCallTable
            tableHeader={tableHeader}
            tableData={requests}
            loading={loading}
            companies={companies}
            teamLeaders={teamLeaders}
            handleSearch={handleSearch}
            setSearchKey={setSearchKey}
            searchKey={searchKey}
            noDataFoundShow={noDataFoundShow}
            managers={managers}
            handleFilter={handleFilter}
            totalPages={totalPages}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </div>
      </div>
    </>
  );
};

export default Admin;
