import React, { useEffect, useState } from "react";
import {
  getTeamLeaderRequestCalls,
  getDispatchers,
  searchTeamLeader,
} from "../../API";
import { useDispatch, useSelector } from "react-redux";
import {
  addRequest,
  setRequests,
  updateRequest,
} from "../../redux/reducers/requestReducer";
import TeamLeaderRequestCallTable from "../../components/TeamLeaderRequestCallTable";
import {
  checkTeamLeaderRequests,
  updateReceiveStatus,
} from "../../components/socket/StatusSocket";
import { resevedTimer } from "../../components/socket/timerSocket";
import { logoutReducer } from "../../redux/reducers/authReducer";
import { ALL_JOBS, IN_PROGRESS, PENDING } from "../../GlobalFunctions";

const RequestedCalls = () => {
  const [loading, setLoading] = useState(false);
  const [dispatchers, setDispatchers] = useState([]);
  const [searchKey, setSearchKey] = useState("Select Filter");

  const { userId } = useSelector((state) => state.auth);
  const { requests } = useSelector((state) => state.requests);
  const { sideBar } = useSelector((state) => state.setSideBar);
  const [totals, setTotals] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const dispatch = useDispatch();

  const handleGetRequestData = () => {
    setLoading(true);
    getTeamLeaderRequestCalls(currentPage, 10).then((res) => {
      console.log("====================================");
      console.log(res);
      console.log("====================================");
      if (res.success) {
        dispatch(setRequests(res.requestTech));
        setTotals({
          totalRequestCalls: res.requestTechTotal,
          totalToDispatcher: res.requestTechToDispatcher,
          totalInProgress: res.requestTechToTechnician,
        });
        setTotalPages(res.totalPages);
      } else if (res === "The token is invalid or expired") {
        dispatch(logoutReducer());
      }
    });

    getDispatchers().then((res) => {
      if (res.success) {
        setDispatchers(res.dispatchers);
        setLoading(false);
      }
    });
  };

  const handleSearch = (search) => {
    if (searchKey === "name" || searchKey === "workOrderNumber") {
      searchTeamLeader(searchKey, search)
        .then((res) => {
          if (res.success) {
            dispatch(setRequests(res.requestCalls));
          } else {
            if (res === "The token is invalid or expired") {
              dispatch(logoutReducer());
            }
          }
        })
        .catch((error) => {
          console.error("Error searching requested calls:", error);
        });
    } else {
    }
  };

  useEffect(() => {
    handleGetRequestData();
    updateReceiveStatus(dispatch, updateRequest);
    checkTeamLeaderRequests(dispatch, addRequest, userId, updateRequest);
    resevedTimer(dispatch, updateRequest);
  }, [currentPage]);

  const handleFilter = (filter) => {
    if (filter === ALL_JOBS) {
      handleGetRequestData();
    } else if (filter === PENDING) {
      setLoading(true);
      getTeamLeaderRequestCalls(currentPage, 10).then((res) => {
        if (res.success) {
          const pendingRequests = res.requestTech.filter(
            (request) => request.status === "To Dispatcher"
          );
          dispatch(setRequests(pendingRequests));
          setTotalPages(res.totalPages);
          setLoading(false);
        } else {
          if (res === "The token is invalid or expired") {
            dispatch(logoutReducer());
          }
        }
      });
    } else if (filter === IN_PROGRESS) {
      setLoading(true);
      getTeamLeaderRequestCalls(currentPage, 10).then((res) => {
        if (res.success) {
          const inProgressRequests = res.requestTech.filter(
            (request) => request.status === "Technician Checked In"
          );
          dispatch(setRequests(inProgressRequests));
          setTotalPages(res.totalPages);
          setLoading(false);
        } else {
          if (res === "The token is invalid or expired") {
            dispatch(logoutReducer());
          }
        }
      });
    }
  };

  const tableHeader = [
    "#",
    "Name",
    "Location",
    "Work Order Number",
    "Time To Arrive",
    "Status",
    "Select Dispatcher",
    "Action",
  ];

  return (
    <>
      <div className={`p-4 ${sideBar && "sm:ml-64"}`}>
        <div className="p-4 rounded-lg mt-14">
          <span className="text-2xl font-bold text-gray-900 dark:text-white mb-4">
            Team Leader Requested Calls
          </span>
          <TeamLeaderRequestCallTable
            tableHeader={tableHeader}
            tableData={requests}
            loading={loading}
            dispatchers={dispatchers}
            handleSearch={handleSearch}
            setSearchKey={setSearchKey}
            searchKey={searchKey}
            totals={totals}
            handleFilter={handleFilter}
            totalPages={totalPages}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </div>
      </div>
    </>
  );
};

export default RequestedCalls;
