import React, { useEffect, useState } from "react";

const Search = ({ handleSearch, setSearchKey, searchKey }) => {
  const [selected, setSelected] = useState(false);
  const [selectedValue, setSelectedValue] = useState("");

  useEffect(() => {
    if (selectedValue && searchKey) {
      handleSearch(selectedValue);
    }
  }, [searchKey, selectedValue]);

  const onKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSearch(selectedValue);
    }
  };
  return (
    <>
      <div className="flex">
        <button
          id="dropdown-button"
          data-dropdown-toggle="dropdown"
          className="flex-shrink-0 z-10 inline-flex items-center py-2.5 px-4 text-sm font-medium text-center text-gray-900 bg-gray-100 border border-gray-300 rounded-l-lg hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:focus:ring-gray-700 dark:text-white dark:border-gray-600"
          type="button"
          onClick={() => setSelected(!selected)}
        >
          {searchKey === "workOrderNumber"
            ? "Work Order Number"
            : searchKey === "name"
            ? "Name"
            : "Search Filter"}
          <svg
            aria-hidden="true"
            className="w-4 h-4 ml-1"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clipRule="evenodd"
            ></path>
          </svg>
        </button>

        <div className="relative w-full">
          <input
            type="text"
            id="table-search"
            className="block p-2.5 z-20 text-sm text-gray-900 bg-gray-50 rounded-r-lg border-l-gray-50 border-l-2 border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-l-gray-700  dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:border-blue-500"
            placeholder="Search for requests"
            onChange={(e) => {
              handleSearch(e.target.value);
              setSelectedValue(e.target.value);
            }}
            onKeyDown={onKeyPress}
          />
        </div>
      </div>
      {selected && (
        <div
          id="dropdown"
          className="z-10 bg-white divide-y absolute divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700"
        >
          <ul
            className="py-2 text-sm text-gray-700 dark:text-gray-200"
            aria-labelledby="dropdown-button"
          >
            <li>
              <button
                type="button"
                className="inline-flex w-full px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                onClick={() => {
                  setSearchKey("name");
                  setSelected(false);
                }}
              >
                Name
              </button>
            </li>
            <li>
              <button
                type="button"
                className="inline-flex w-full px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                onClick={() => {
                  setSearchKey("workOrderNumber");
                  setSelected(false);
                }}
              >
                Work Order Number
              </button>
            </li>
          </ul>
        </div>
      )}
    </>
  );
};

export default Search;
