import io from "socket.io-client";

const chatSocket = io(`${process.env.REACT_APP_SOCKET_API}/chat-socket`, {
  autoConnect: false,
}); // replace with your server URL

const sendChat = (data) => {
  chatSocket.emit("sendChat", data);
};

const getChat = (data) => {
  chatSocket.emit("getChat", data);
};

const reseveChat = (dispatch, setChat) => {
  return chatSocket.on("reseveChat", (data) => {
    dispatch(setChat(data));
  });
};

export { chatSocket, sendChat, getChat, reseveChat };
