import React, { useEffect, useState } from "react";
import CompletedRequestTable from "../../components/CompletedRequestTable";
import {
  getAllCompletedRequests,
  getCompanies,
  getTeamLeaders,
  searchCompletedRequestedCalls,
} from "../../API";
import { useDispatch, useSelector } from "react-redux";
import {
  setRequests,
  addRequest,
  updateRequest,
} from "../../redux/reducers/requestReducer";
import {
  resevedStatus,
  updateReceiveStatus,
} from "../../components/socket/StatusSocket";
import { resevedTimer } from "../../components/socket/timerSocket";
import { logoutReducer } from "../../redux/reducers/authReducer";

const CompletedRequests = () => {
  const [loading, setLoading] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [teamLeaders, setTeamLeaders] = useState([]);
  const [searchKey, setSearchKey] = useState("Select Filter");
  const [noDataFoundShow, setNoDataFoundShow] = useState(false);
  const { sideBar } = useSelector((state) => state.setSideBar);
  const [totals, setTotals] = useState(0);

  const dispatch = useDispatch();

  const handleGetRequestData = () => {
    setLoading(true);
    getAllCompletedRequests().then((res) => {
      if (res.success) {
        dispatch(setRequests(res.requestTech));
        setTotals(res.requestTechTotal);
      } else {
        setNoDataFoundShow(true);
      }
    });

    getCompanies().then((res) => {
      if (res.companies) {
        setCompanies(res.companies);
      }
    });

    getTeamLeaders().then((res) => {
      if (res.success) {
        setTeamLeaders(res.teamLeader);
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    handleGetRequestData();
    resevedStatus(dispatch, addRequest);
    updateReceiveStatus(dispatch, updateRequest);
    resevedTimer(dispatch, updateRequest);
  }, []);

  const { requests } = useSelector((state) => state.requests);
  const tableHeader = [
    "#",
    "Name",
    "Location",
    "Work Order Number",
    "Time To Arrive",
    "Status",
  ];

  const handleSearch = (search) => {
    if (searchKey === "name" || searchKey === "workOrderNumber") {
      searchCompletedRequestedCalls(searchKey, search)
        .then((res) => {
          if (res.success) {
            dispatch(setRequests(res.requestCalls));
          } else {
            setNoDataFoundShow(true);
            if (res === "The token is invalid or expired") {
              dispatch(logoutReducer());
            }
          }
        })
        .catch((error) => {
          console.error("Error searching requested calls:", error);
          setNoDataFoundShow(true);
        });
    } else {
      // Handle other search keys or cases here
    }
  };

  return (
    <>
      <div className={`p-4 ${sideBar && "sm:ml-64"}`}>
        <div className="p-4 rounded-lg mt-14">
          <span className="text-2xl font-bold text-gray-900 dark:text-white mb-4">
            Completed Requested Calls
          </span>
          <CompletedRequestTable
            tableHeader={tableHeader}
            tableData={requests}
            loading={loading}
            companies={companies}
            teamLeaders={teamLeaders}
            handleSearch={handleSearch}
            setSearchKey={setSearchKey}
            searchKey={searchKey}
            noDataFoundShow={noDataFoundShow}
            totals={totals}
          />
        </div>
      </div>
    </>
  );
};

export default CompletedRequests;
