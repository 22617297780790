import io from "socket.io-client";

const statusSocket = io(`${process.env.REACT_APP_SOCKET_API}/status-socket`, {
  autoConnect: false,
}); // replace with your server URL

const sendStatus = (data) => {
  statusSocket.emit("sendStatus", data);
};

const resevedStatus = (dispatch, addRequest) => {
  return statusSocket.on("receiveStatus", (data) => {
    dispatch(addRequest(data));
  });
};

const sendUpdateStatus = (data) => {
  statusSocket.emit("updateStatus", data);
};

const updateReceiveStatus = (dispatch, updateRequest) => {
  return statusSocket.on("updateReceiveStatus", (data) => {
    dispatch(updateRequest(data));
  });
};

const checkTeamLeaderRequests = (
  dispatch,
  addRequest,
  currentUserId,
  updateRequest
) => {
  return statusSocket.on("updateReceiveStatusTeamLead", (data) => {
    if (currentUserId === data?.teamLead?._id) {
      if (
        data?.status === "To Dispatcher" ||
        data?.status === "Waiting To Confirm" ||
        data?.status === "Technician Checked In" ||
        data?.status === "To Accountant" ||
        data?.status === "On Pay" ||
        data?.status === "Closed" ||
        data?.status === "Cancelled" ||
        data?.status === "Waiting For Approval" ||
        data?.status === "ETA Needed"
      ) {
        dispatch(updateRequest(data));
      } else {
        dispatch(addRequest(data));
      }
    }
  });
};

const checkDispatcherRequests = (
  dispatch,
  addRequest,
  currentUserId,
  updateRequest
) => {
  return statusSocket.on("updateReceiveStatusDispatcher", (data) => {
    if (currentUserId === data?.dispatcher?._id) {
      if (
        data?.status === "To TeamLead" ||
        data?.status === "Waiting To Confirm"
      ) {
        dispatch(updateRequest(data));
      } else {
        dispatch(addRequest(data));
      }
    }
  });
};

export {
  sendStatus,
  resevedStatus,
  statusSocket,
  sendUpdateStatus,
  updateReceiveStatus,
  checkTeamLeaderRequests,
  checkDispatcherRequests,
};
