import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { deleteNotification } from "../../API";
import {
  addNotificationNumber,
  deleteNotificationRed,
} from "../../redux/reducers/notificationReducer";
import { useNavigate } from "react-router";

const Notification = () => {
  const { notifications } = useSelector((state) => state.notification);

  const dispatch = useDispatch();
  const navigation = useNavigate();

  const handleDeleteNotification = (notificationId, item) => {
    deleteNotification(notificationId).then((res) => {
      if (res.success) {
        dispatch(deleteNotificationRed(notificationId));
        dispatch(addNotificationNumber(notifications.length - 1));
        navigation(
          `/request-job/details/${item._id || item.requestTechId._id}`
        );
      }
    });
  };

  return (
    <>
      
      {notifications.length !== 0 ? (
        <>
          {notifications?.map((e) => {
            const getTimeDifference = (createdAt) => {
              const now = new Date();
              const created = new Date(createdAt);

              const diffInMilliseconds = Math.abs(now - created);
              const diffInSeconds = Math.floor(diffInMilliseconds / 1000);
              const diffInMinutes = Math.floor(diffInSeconds / 60);
              const diffInHours = Math.floor(diffInMinutes / 60);
              const diffInDays = Math.floor(diffInHours / 24);

              if (diffInSeconds < 60) {
                return "A few moments ago";
              } else if (diffInMinutes === 1) {
                return "One minute ago";
              } else if (diffInMinutes < 60) {
                return `${diffInMinutes} minutes ago`;
              } else if (diffInHours === 1) {
                return "One hour ago";
              } else if (diffInHours < 24) {
                return `${diffInHours} hours ago`;
              } else if (diffInDays === 1) {
                return "One day ago";
              } else {
                return `${diffInDays} days ago`;
              }
            };
            return (
              <>
                <div className="divide-y divide-gray-100 dark:divide-gray-700">
                  <div
                    className="notification-btn flex hover:bg-gray-100 dark:hover:bg-gray-700"
                    onClick={() => handleDeleteNotification(e._id, e)}
                  >
                    <div className="w-full pl-3" style={{padding: "10px"}}>
                      <span className="text-gray-500 text-sm mb-1.5 dark:text-gray-400">
                        {e.message || e.title}
                      </span><br/>
                      <span className="text-xs text-blue-600 dark:text-blue-500">
                        {getTimeDifference(e.createdAt)}
                      </span>
                    </div>
                  </div>
                </div>
              </>
            );
          })}
        </>
      ) : (
        <>
          <div className="flex justify-center items-center h-32">
            <div className="flex flex-col justify-center items-center">
              <p className="text-white">No Notification</p>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Notification;
