import React, { useEffect } from "react";
import UserTable from "../../components/UserTable";
import { getUsersStatus, searchUser } from "../../API";
import { useState } from "react";
import { useSelector } from "react-redux";
import { receiveUsersStatus } from "../../components/socket/userStatus";

const User = () => {
  const tableHeader = [
    "Name",
    "Role",
    "Last signed in at",
    "Timer",
    "Status",
    "Action",
  ];
  const [userStates, setUsersStatus] = useState([]);
  const [loading, setLoading] = useState(false);
  const { sideBar } = useSelector((state) => state.setSideBar);

  const handleGetUserStatus = () => {
    setLoading(true);
    getUsersStatus().then((res) => {
      if (res.success) {
        setUsersStatus(res.userStatus);
        setLoading(false);
      }
    });
  };

  const searchResult = (search) => {
    searchUser(search).then((res) => {
      if (res.success) {
        setUsersStatus(res.user);
      } else {
        handleGetUserStatus();
      }
    });
  };

  useEffect(() => {
    handleGetUserStatus();
    receiveUsersStatus(setUsersStatus);
  }, []);
  return (
    <div className={`p-4 ${sideBar && "sm:ml-64"}`}>
      <div className="p-4 rounded-lg mt-14">
        <span className="text-2xl font-bold text-gray-900 dark:text-white">
          Users
        </span>
        <UserTable
          tableHeader={tableHeader}
          tableData={userStates}
          loading={loading}
          setSearch={searchResult}
        />
      </div>
    </div>
  );
};

export default User;
