import React from "react";
import TableLoading from "../../components/Loading/TableLoading";
import EmptyData from "../../components/EmptyData";
import { useState } from "react";
import { confirmPaymentRequest, getAllPaymentRequest } from "../../API";

const PaymentRequests = () => {
  const tableHeader = [
    "Name",
    "Phone number",
    "Status",
    "Amount",
    "Payment Method",
    "Tax ID",
    "Action",
  ];
  const [technicians, setTechnicians] = useState([]);
  const [loading, setLoading] = useState(false);
  const [noDataFoundShow, setNoDataFoundShow] = useState(false);

  const handleSubmit = (paymentRequestId) => {
    //
    confirmPaymentRequest(paymentRequestId).then((data) => {
      if (data?.success) {
        handleGetData();
      }
    });
  };

  const handleGetData = () => {
    setLoading(true);
    getAllPaymentRequest().then((data) => {
      setTechnicians(data?.paymentRequests);
      setLoading(false);
      if (data?.paymentRequests?.length === 0) {
        setNoDataFoundShow(true);
      } else {
        setNoDataFoundShow(false);
      }
    });
  };

  React.useEffect(() => {
    handleGetData();
  }, []);
  return (
    <>
      <div className={`p-4 ${"sm:ml-64"}`}>
        <div className="p-4 rounded-lg mt-14">
          <span className="text-2xl font-bold text-gray-900 dark:text-white mb-4">
            Payment Request
          </span>
          {loading ? (
            <TableLoading />
          ) : (
            <>
              {noDataFoundShow ? (
                <EmptyData />
              ) : (
                <div className="relative overflow-x-auto shadow-md sm:rounded-lg mt-4">
                  <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                      <tr>
                        {tableHeader?.map((item, index) => (
                          <th scope="col" className="px-6 py-3" key={index}>
                            <div className="flex items-center">{item}</div>
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {technicians?.map((data, index) => {
                        return (
                          <tr
                            className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                            key={data._id}
                          >
                            <td className="px-6 py-4">
                              {data?.technicianName}
                            </td>
                            <td className="px-6 py-4">{data?.phoneNumber}</td>
                            <td className="px-6 py-4 text-green-500">
                              {data?.status}
                            </td>
                            <td className="px-6 py-4">{data?.amount}</td>
                            <td className="px-6 py-4">{data?.paymentMethod}</td>
                            <td className="px-6 py-4">{data.taxId}</td>
                            <td>
                              <button
                                type="submit"
                                className={`py-2 px-3 text-sm font-medium text-center text-white bg-blue-600 rounded-lg ${
                                  data?.status === "Paid"
                                    ? ""
                                    : "hover:bg-blue-700"
                                } focus:ring-4 focus:outline-none focus:ring-red-300 dark:bg-blue-500  ${
                                  data?.status === "Paid"
                                    ? ""
                                    : "dark:hover:bg-blue-600 dark:focus:ring-blue-900"
                                } `}
                                onClick={() => handleSubmit(data?._id)}
                                disabled={
                                  data?.status === "Paid" ? true : false
                                }
                              >
                                Submit
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default PaymentRequests;
