import React, { useState, useEffect } from "react";
import TableLoading from "./Loading/TableLoading";
import EmptyData from "../components/EmptyData";
import { renderPaginationButtons } from "../GlobalFunctions";

const TechniciansTable = ({
  tableHeader,
  loading,
  setSearch,
  noDataFoundShow,
  technicians,
  totalPages,
  currentPage,
  setCurrentPage,
}) => {
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    setCurrentPage(1); // Reset the current page when the search term changes
  }, [searchTerm]);

  const handleSearch = (search) => {
    setSearchTerm(search);
    setSearch(search);
  };

  return (
    <>
      {loading ? (
        <TableLoading />
      ) : (
        <>
          {noDataFoundShow ? (
            <EmptyData />
          ) : (
            <div className="relative overflow-x-auto shadow-md sm:rounded-lg mt-4">
              <label htmlFor="table-search" className="sr-only">
                Search
              </label>
              <div className="relative mb-4">
                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                  <svg
                    className="w-5 h-5 text-gray-500 dark:text-gray-400"
                    aria-hidden="true"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </div>
                <input
                  type="text"
                  id="table-search-techs"
                  className="block p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Search for technicians"
                  value={searchTerm}
                  onChange={(e) => handleSearch(e.target.value)}
                />
              </div>
              <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                    {tableHeader?.map((item, index) => (
                      <th scope="col" className="px-6 py-3" key={index}>
                        <div className="flex items-center">
                          {item}
                          {item === "Name" ||
                            item === "Location" ||
                            (item === "Created At" && (
                              <button type="button">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="w-3 h-3 ml-1"
                                  aria-hidden="true"
                                  fill="currentColor"
                                  viewBox="0 0 320 512"
                                >
                                  <path d="M27.66 224h264.7c24.6 0 36.89-29.78 19.54-47.12l-132.3-136.8c-5.406-5.406-12.47-8.107-19.53-8.107c-7.055 0-14.09 2.701-19.45 8.107L8.119 176.9C-9.229 194.2 3.055 224 27.66 224zM292.3 288H27.66c-24.6 0-36.89 29.77-19.54 47.12l132.5 136.8C145.9 477.3 152.1 480 160 480c7.053 0 14.12-2.703 19.53-8.109l132.3-136.8C329.2 317.8 316.9 288 292.3 288z" />
                                </svg>
                              </button>
                            ))}
                        </div>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {technicians?.map((data, index) => {
                    return (
                      <tr
                        className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                        key={data._id}
                      >
                        <td className="px-6 py-4">{data?.name}</td>
                        <td className="px-6 py-4">{data?.phone}</td>
                        <td className="px-6 py-4">
                          {data?.state && data?.country
                            ? `${data.state}, ${data.country}`
                            : "No Location"}
                        </td>
                        <td className="px-6 py-4">
                          {data?.rating ? data.rating : "No Rate"}
                        </td>
                        <td className="px-6 py-4">
                          {data?.review ? data.review : "No Review"}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <div className="flex justify-center items-center">
                {renderPaginationButtons(currentPage, totalPages, setCurrentPage)}
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default TechniciansTable;
