import React from "react";
import NoData from "../images/No data-amico.png";

const EmptyData = () => {
  return (
    <div className="flex justify-center">
      <img src={NoData} alt="My SVG file" className="w-2/4" />
    </div>
  );
};

export default EmptyData;
