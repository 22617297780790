import instance from "./axiosInstance";

if (sessionStorage.getItem("token")) {
  instance.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${sessionStorage.getItem("token")}`;
  instance.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
}

export const login = async (email, password, fcmToken) => {
  try {
    const response = await instance.post(`/login`, {
      email,
      password,
      fcmToken,
    });

    return response.data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const logout = async (userId) => {
  try {
    const response = await instance.post(`/logout`, {
      userId,
    });

    return response.data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const getAllRequestData = async (currentPage, perPage) => {
  try {
    const response = await instance.get(`/get-all-requestTech`, {
      params: {
        page: currentPage,
        perPage: perPage,
      },
    });

    return response.data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const getRequestTechByAccountant = async () => {
  try {
    const response = await instance.get(`/get-request-techBy-accountant`);

    return response.data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const getRequestById = async (id) => {
  try {
    const response = await instance.get(`/get-requestTech-by-id/${id}`);

    return response.data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const getCompanies = async () => {
  try {
    const response = await instance.get(`/get-companies`);

    return response.data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const getTeamLeaders = async () => {
  try {
    const response = await instance.get(`/get-team-leader`);

    return response.data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const sendRequestToTeamLeader = async (
  _id,
  company,
  teamLead,
  managerId
) => {
  try {
    const response = await instance.put("/add-company-teamlead-request", {
      _id,
      company,
      teamLead,
      managerId,
    });

    return response.data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const getUsersStatus = async () => {
  try {
    const response = await instance.get("/users-online-status");

    return response.data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const requestCall = async (
  name,
  email,
  phone,
  location,
  description,
  workOrderNumber,
  nteOption,
  hourlyRate,
  eta,
  trip,
  emergency,
  emergencyHourlyRate
) => {
  try {
    const response = await instance.post("/create-requestCall", {
      name,
      email,
      phone,
      location,
      description,
      workOrderNumber,
      nteOption,
      hourlyRate,
      eta,
      trip,
      emergencyHourlyRate,
      emergencyEta: emergency,
    });

    return response.data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const getTeamLeaderRequestCalls = async (currentPage, perPage) => {
  try {
    const response = await instance.get("/get-requestTech-by-teamlead", {
      params: {
        page: currentPage,
        perPage: perPage,
      },
    });

    return response.data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const getDispatchers = async () => {
  try {
    const response = await instance.get("/get-dispatcher");

    return response.data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const requestCallToDispatcher = async (_id, dispatcher) => {
  try {
    const response = await instance.put("/update-dispatcher-request", {
      _id,
      dispatcher,
    });

    return response.data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const getRequestCallByDispatchers = async (currentPage, perPage) => {
  try {
    const response = await instance.get("/get-requestTech-by-dispatcher", {
      params: {
        page: currentPage,
        perPage: perPage,
      },
    });

    return response.data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const createTechnician = async (
  name,
  phone,
  RequestTechSchemaId,
  paymentMethod,
  country,
  state,
  zip
) => {
  try {
    const response = await instance.post("/create-tech", {
      name,
      phone,
      RequestTechSchemaId,
      paymentMethod,
      country,
      state,
      zip,
    });

    return response.data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const updateTimeToArrived = async (_id, timeToArrived) => {
  try {
    const response = await instance.post("/update-time-to-arrived", {
      _id,
      timeToArrived,
    });

    return response.data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const updateTechnicianCheckin = async (_id) => {
  try {
    const response = await instance.put("/technicianCheckedIn", {
      _id,
    });

    return response.data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const updateTechnicianCheckout = async (_id, key) => {
  try {
    const response = await instance.put("/techCheckoutChangeStatus", {
      _id,
      key,
    });

    return response.data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const getAllRoles = async () => {
  try {
    const response = await instance.get("/fetch-roles");

    return response.data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const AddUser = async (name, email, password, role, phone) => {
  try {
    const response = await instance.post("/create-user", {
      name,
      email,
      password,
      role,
      phone,
    });

    return response.data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const uploadDocument = (key, formData, id) => {
  return instance.post(`/upload-user-image?key=${key}&issueId=${id}`, formData);
};

export const addProposalNTE = (reqId, proposal) => {
  return instance.post(`/create-issue?requestTechId=${reqId}`, {
    proposerNTE: proposal,
  });
};

export const addPricess = async (reqId, techPrice, costPrice) => {
  try {
    const response = await instance.put(`/add-prices/${reqId}`, {
      techPrice,
      costPrice,
    });

    return response.data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const updateTech = async (
  _id,
  name,
  phone,
  paymentMethod,
  country,
  state
) => {
  try {
    const response = await instance.put("/update-tech", {
      _id,
      name,
      phone,
      paymentMethod,
      country,
      state,
    });

    return response.data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const updateStatusToConfirmed = async (reqId) => {
  try {
    const response = await instance.put(`/update-status-to-confirmed`, {
      _id: reqId,
    });

    return response.data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const getAllCompletedRequests = async () => {
  try {
    const response = await instance.get(`/get-all-completed-requests`);

    return response.data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const updateStatusToCompleted = async (reqId) => {
  try {
    const response = await instance.put(`/update-status-to-completed`, {
      _id: reqId,
    });

    return response.data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const updateUser = async (payload) => {
  try {
    const response = await instance.put(`/update-user/${payload.id}`, payload);

    return response.data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const deleteUser = async (id) => {
  try {
    const response = await instance.delete(`/delete-user/${id}`);

    return response.data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const getUser = async (id) => {
  try {
    const response = await instance.get(`/user-details/${id}`);

    return response.data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const uploadImage = async (key, formData) => {
  return instance.post(`/upload-user-image?key=${key}`, formData);
};

export const ResetPasswordSetting = async (payload) => {
  try {
    const response = await instance.put(`/reset-password`, {
      currentPassword: payload.currentPassword,
      newPassword: payload.newPassword,
      confirmPassword: payload.confirmPassword,
    });

    return response.data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const forgetPassword = async (email) => {
  try {
    const response = await instance.post("/forget-password", { email });

    return response.data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const resetPassword = async (payload) => {
  try {
    const response = await instance.put(
      `/reset-password-link/${payload.userId}`,
      {
        newPassword: payload.newPassword,
        confirmPassword: payload.confirmPassword,
      }
    );

    return response.data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const addCost = async (payload) => {
  try {
    const response = await instance.post(`/craete-cost`, {
      name: payload.name,
      cost: payload.cost,
      information: payload.information,
      reqId: payload.reqId,
    });

    return response.data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const getAllCosts = async (id) => {
  try {
    const response = await instance.get(`/fetch-cost/${id}`);

    return response.data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const NTEPriceAdded = async (payload) => {
  try {
    const response = await instance.post(`/add-nte-price`, {
      reqId: payload.reqId,
      NTEPrice: payload.NTEPrice,
    });

    return response.data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const getCommission = async () => {
  try {
    const response = await instance.get("/get-current-user-commotion");

    return response.data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const sendToInvoicer = async (_id) => {
  try {
    const response = await instance.put(`/send-request-call-to-invoicer`, {
      _id,
    });

    return response.data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const getNotification = async () => {
  try {
    const response = await instance.get("/get-notifications");

    return response.data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const getInvoicerRequest = async (currentPage, perPage) => {
  try {
    const response = await instance.get(`/get-requestTech-by-invoicer`, {
      params: {
        page: currentPage,
        perPage: perPage,
      },
    });

    return response.data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const slectedATech = async (reqId, techId) => {
  try {
    const response = await instance.put(`/selected-tech`, {
      reqId,
      techId,
    });

    return response.data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const updateDeadLine = async (payload) => {
  try {
    const response = await instance.put("/add-tech-time-in-site", {
      _id: payload._id,
      timeInSiteFrom: payload.from,
      timeInSiteTo: payload.to,
    });

    return response.data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const deleteNotification = async (notificationId) => {
  try {
    const response = await instance.delete(
      `/delete-notification/${notificationId}`
    );
    return response.data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const addTechReview = async (payload) => {
  try {
    const response = await instance.put("/add-tech-review", {
      review: payload.review,
      rating: payload.rating,
      _id: payload._id,
    });

    return response.data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const cancelJob = async (payload) => {
  try {
    const response = await instance.put("/update-status-cancelled", {
      _id: payload._id,
    });

    return response.data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const getRequestCallByManager = async () => {
  try {
    const response = await instance.get("/get-all-request-by-manager");

    return response.data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const getAllManagers = async () => {
  try {
    const response = await instance.get("/users-manager");
    return response.data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const getAllTechnicians = async (currentPage, perPage) => {
  try {
    const response = await instance.get(`/get-techs`, {
      params: {
        page: currentPage,
        perPage: perPage,
      },
    });
    return response.data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const getAllTechniciansDispatcher = async (currentPage, perPage) => {
  try {
    const response = await instance.get(`/get-techs-by-dispatcher`, {
      params: {
        page: currentPage,
        perPage: perPage,
      },
    });
    return response.data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const searchTechsDispatcher = async (search) => {
  try {
    const response = await instance.get(
      `/search-technicians-dispatcher?search=${search}`
    );
    return response.data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const searchTechsDispatcherByName = async (search) => {
  try {
    const response = await instance.get(
      `/search-technicians-dispatcher-name?search=${search}`
    );
    return response.data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const searchUser = async (user) => {
  try {
    const response = await instance.get(`/search-user/${user}`);
    return response.data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const deleteImages = async (payload) => {
  try {
    const response = await instance.post(
      `/delete-issue-image?issueId=${payload._id}`,
      {
        key: payload.key,
        imageURL: payload.imageURL,
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const searchRequestedCalls = async (key, name) => {
  try {
    const response = await instance.get(
      `/search-request-calls?name=${name}&key=${key}`
    );
    return response.data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const searchCompletedRequestedCalls = async (key, name) => {
  try {
    const response = await instance.get(
      `/search-completed-request-calls?name=${name}&key=${key}`
    );
    return response.data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const searchTechs = async (search) => {
  try {
    const response = await instance.get(`/search-technicians?search=${search}`);
    return response.data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const searchDispatcherRequestCall = async (key, name) => {
  try {
    const response = await instance.get(
      `/search-dispatcher-request-calls?name=${name}&key=${key}`
    );
    return response.data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const searchAccountant = async (key, name) => {
  try {
    const response = await instance.get(
      `/search-accountant-request-calls?name=${name}&key=${key}`
    );
    return response.data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const searchInvoicer = async (key, name) => {
  try {
    const response = await instance.get(
      `/search-invoicer-request-calls?name=${name}&key=${key}`
    );
    return response.data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const searchTeamLeader = async (key, name) => {
  try {
    const response = await instance.get(
      `/search-teamleader-request-calls?name=${name}&key=${key}`
    );
    return response.data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const updateJob = async (
  name,
  email,
  phone,
  location,
  description,
  workOrderNumber,
  requestId
) => {
  try {
    const response = await instance.put(`/update-request-calls/${requestId}`, {
      name,
      email,
      phone,
      location,
      description,
      workOrderNumber,
    });

    return response.data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const getTotalData = async () => {
  try {
    const response = await instance.get("/get-totals");

    return response.data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const exportData = async () => {
  try {
    const response = await instance.get(`/export-excel`);

    return response.data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const getChats = async (requestId) => {
  try {
    const response = await instance.get(`/get-chats/${requestId}`);

    return response.data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const getPaymentRequest = async (requestId) => {
  try {
    const response = await instance.get(
      `/get-payment-requests-by-request/${requestId}`
    );

    return response.data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const deleteRequestApi = async (id) => {
  try {
    const response = await instance.delete(`/delete-request-calls/${id}`);

    return response.data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const deleteCostAPi = async (id) => {
  try {
    const response = await instance.delete(`/delete-cost/${id}`);

    return response.data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const addPaymentRequest = async (payload) => {
  try {
    const response = await instance.post(`/create-payment-request`, payload);
    return response.data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const getAllPaymentRequest = async () => {
  try {
    const response = await instance.get("/get-payment-requests-by-user");
    return response.data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const confirmPaymentRequest = async (paymentRequestId) => {
  try {
    console.log({ paymentRequestId });
    const response = await instance.put(
      `/confirm-payment-request/${paymentRequestId}`
    );
    return response.data;
  } catch (error) {
    return error.response.data.message;
  }
};
