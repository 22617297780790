import React, { useEffect, useState } from "react";
import TechniciansTable from "../../components/TechniciansTable";
import { getAllTechniciansDispatcher, searchTechsDispatcher } from "../../API";

const TechniciansDispatcher = () => {
  const tableHeader = ["Name", "Phone number", "Location", "Rate", "Review"];
  const [technicians, setTechnicians] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    getAllTechniciansDispatcher(currentPage, 10).then((res) => {
      if (res.success) {
        setTechnicians(res.techs);
        setTotalPages(res.totalPages);
      }
    });
  }, [currentPage]);

  const searchResult = (search) => {
    searchTechsDispatcher(search).then((res) => {
      if (res.success) {
        setTechnicians(res.tech);
        setTotalPages(Math.ceil(res.tech.length / 10));
        setCurrentPage(1);
      }
    });
  };

  return (
    <>
      <div className={`p-4 ${"sm:ml-64"}`}>
        <div className="p-4 rounded-lg mt-14">
          <span className="text-2xl font-bold text-gray-900 dark:text-white mb-4">
            Technicians
          </span>
          <TechniciansTable
            tableHeader={tableHeader}
            technicians={technicians}
            totalPages={totalPages}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            setSearch={searchResult}
          />
        </div>
      </div>
    </>
  );
};

export default TechniciansDispatcher;
