import React from "react";

export const ForgetPasswordConfirmation = () => {
  return (
    <div>
      <div className="grid place-items-center m-0" style={{ height: "89vh" }}>
        <div className="w-full max-w-xl">
          <div className="my-7 text-center">
            <span className="text-white text-6xl font-semibold">
              Thank you!
            </span>
            <h1 className="text-xl text-white my-4">
              A confirmation link has been sent to your email. <br />
              Please check your email
            </h1>
          </div>
        </div>
      </div>
    </div>
  );
};
