import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./reducers/authReducer";
import requestReducer from "./reducers/requestReducer";
import chatReducer from "./reducers/chatReducer";
import costReducer from "./reducers/costReducer";
import notificationReducer from "./reducers/notificationReducer";
import sideBarReducer from "./reducers/sideBarReducer";
import paymentRequests from "./reducers/paymentRequestReducer";

export default configureStore({
  reducer: {
    auth: authReducer,
    requests: requestReducer,
    chat: chatReducer,
    cost: costReducer,
    notification: notificationReducer,
    setSideBar: sideBarReducer,
    paymentRequest: paymentRequests,
  },
});
