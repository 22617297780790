import { createSlice } from "@reduxjs/toolkit";

export const notification = createSlice({
  name: "notification",
  initialState: {
    notifications: [],
    notificationNumber: 0,
  },

  reducers: {
    setNotification: (state, action) => {
      state.notifications = action.payload;
    },
    addNotification: (state, action) => {
      state.notifications = [...state.notifications, action.payload].reverse();
    },

    addNotificationNumber: (state, action) => {
      state.notificationNumber = action.payload;
    },

    deleteNotificationRed: (state, action) => {
      state.notifications = state.notifications.filter(
        (e) => e._id !== action.payload
      );
    },
  },
});

export const {
  setNotification,
  addNotification,
  addNotificationNumber,
  deleteNotificationRed,
} = notification.actions;

export default notification.reducer;
